import React from 'react';
import { useRef } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import Kinostyles from '../Util/AutokinoStylesComponent.module.css';

const FilmprogrammSignup = () => {
  const ref = useRef(null);
  var visible = false;
  return (
    <div className={'mt-2'}>
      <button
        className={Kinostyles.CTAButtonNew}
        data-token="79a8b7d7490ce8b0e98cf53e9a1f18e0"
        id="filmprogrammNewsletterButton"
        onClick={event => {
          console.log('showSignup clicked ');
          if (!visible) {
            ref.current.style.display = 'block';
            visible = true;
          } else {
            ref.current.style.display = 'none';
            visible = false;
          }
        }}
      >
        Filmprogramm abonnieren
      </button>
      <iframe
        ref={ref}
        data-w-type="embedded"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://x958m.mjt.lu/wgt/x958m/v6y/form?c=dc49c01c"
        width="100%"
        style={{ display: 'none', height: 0 }}
      />
    </div>
  );
};

export default FilmprogrammSignup;
