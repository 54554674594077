export const updateObject = (oldObject, updatedValues) => {
  return Object.assign({}, oldObject, updatedValues);
};

export function checkValidation(value, rules) {
  let isValid = true;

  if (!rules) return true;

  if (rules.email) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(value).toLowerCase());
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }
  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  return isValid;
}
