import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { getClassName } from '../UI/ResponsiveWrapper/ResponsiveWrapper';
import { useLocation } from 'react-router-dom';

// Constants
import * as ROUTES from '../../constants/routes';
// Styles
import styles from './Header.module.css';
import navLinkStyles from '../UI/NavLinkItem/NavLinkItem.module.css';
// Components
import Icon from '../UI/Icon/Icon';
import NavLinkItem from '../UI/NavLinkItem/NavLinkItem';
import Logo from '../UI/Logo/Logo';
import LogoFlohmarkt from '../UI/Logo/Logo';

import * as actions from '../../store/actions';
import { Dropdown, NavDropdown, NavItem } from 'react-bootstrap';
import FlohmarktLogo from '../UI/FlohmarktLogo/FlohmarktLogo';

class Header extends Component {
  get IconBox() {
    let iconBox = (
      <div onClick={this.props.onShowAuthModal}>
        <Icon icon="user" className={styles.Icon} fontSize={19} />
      </div>
    );

    if (this.props.isAuthenticated) {
      iconBox = (
        <div>
          {/*<Link to={ROUTES.WISHLIST} className={styles.Link}>
            <Icon icon="heart" className={styles.Icon} fontSize={19} />
      </Link> */}
          <Link to={ROUTES.CART} className={styles.Link}>
            <div className={styles.Cart}>
              {this.props.cartCount ? (
                <span className={styles.CartCounter}>
                  {this.props.cartCount}
                </span>
              ) : null}
              <Icon icon="cart" className={styles.Icon} fontSize={19} />
            </div>
          </Link>
          {/*<div onClick={this.props.onLogout}>
            <Icon icon="exit" className={styles.Icon} fontSize={19} />
              </div>*/}
        </div>
      );
    }

    return iconBox;
  }

  render() {
    const pathname = window.location.href;
    console.log('########' + pathname);
    if (pathname && pathname.includes('/flohmarkt')) {
      return (
        <header className={styles.HeaderBackground}>
          <Navbar
            expand="lg"
            variant="dark"
            className={[styles.NavBarContainer, getClassName()].join(' ')}
          >
            <Navbar.Brand className="mr-4">
              <Link to={ROUTES.HOME}>
                <FlohmarktLogo height="60px" width="120px" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className={styles.NavItems + ' mr-auto'}>
              <Nav>
                <NavDropdown
                  title="Flohmarkt"
                  className={styles.DropdownNavLink}
                >
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_STANDPLATZ_BUCHEN}>
                    Standplatz buchen
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.EVENT}>
                    ALLGEMEINES
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_ANREISE}>
                    Anreise
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_FAQ}>
                    FAQ's
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_FOTOS}>
                    Fotos
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_GESCHICHTE}>
                    Geschichte
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.KINDERFLOHMARKT}>
                    Kinderflohmarkt
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_KUNDENLIEBE}>
                    Kundenliebe
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_JOBS_AUTOKINO}>
                    Jobs
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <NavDropdown
                  title="Besucher"
                  className={styles.DropdownNavLink}
                >
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_BESUCHER_TIPPS}>
                    Besucher Tipps
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <NavDropdown
                  title="Verkäufer"
                  className={styles.DropdownNavLink}
                >
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_STANDGEBUEHREN}>
                    Standgebühr
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_MARKTORDNUNG}>
                    Regeln
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_CHECKLIST}>
                    Checklist
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_VERKAEUFER_TIPPS}>
                    Verkaufs Tipps
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <NavDropdown
                  title="Autokino"
                  className={styles.DropdownNavLink}
                >
                  <NavDropdown.Item href={ROUTES.LANDING}>
                    Kinoprogramm
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.UPCOMING_MOVIES}>
                    Bald im Kino
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <NavLinkItem url={ROUTES.CART} exact>
                {this.props.cartCount ? (
                  <span className={styles.CartCounter}>
                    {this.props.cartCount}
                  </span>
                ) : null}
                <Icon icon="cart" className={styles.Icon} fontSize={20} />
              </NavLinkItem>
            </Navbar.Collapse>
          </Navbar>

          <div className={styles.PageTitle}>{getPageTitle()}</div>
        </header>
      );
    } else {
      return (
        <header className={styles.HeaderBackground}>
          <Navbar
            expand="lg"
            variant="dark"
            className={[styles.NavBarContainer, getClassName()].join(' ')}
          >
            <Navbar.Brand>
              <Link to={ROUTES.HOME}>
                <Logo height="60px" width="120px" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              className={styles.NavItems}
              style={{ marginLeft: '10vw' }}
            >
              <Nav>
                <NavDropdown
                  title="Autokino"
                  className={styles.DropdownNavLink}
                >
                  <NavDropdown.Item href={ROUTES.DAS_AUTOKINO}>
                    Erlebnis Autokino
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.EVENT_LOCATION}>
                    Eventlocation
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.SIEGERKINOTEST_AUTOKINO}>
                    Testsieger kino-test.at
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FAQ_AUTOKINO}>
                    Autokino FAQ's
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.OPENAIR_AUTOKINO}>
                    Open Air Kino
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.HAUSTIERE_AUTOKINO}>
                    Haustiere im Kino
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.JOBS_AUTOKINO}>
                    Jobs im Autokino
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <NavDropdown
                  title="Kinoprogramm"
                  className={styles.DropdownNavLink}
                >
                  <NavDropdown.Item href={ROUTES.LANDING}>
                    Kinoprogramm Wien
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.UPCOMING_MOVIES}>
                    Demnächst im Kino
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item href={ROUTES.FAQ_AUTOKINO}>
                      Autokino FAQ's
      </NavDropdown.Item> */}
                </NavDropdown>
              </Nav>
              {/* <NavLinkItem url={ROUTES.LANDING} exact>
                Autokino
      </NavLinkItem> */}
              {/* <NavLinkItem url={ROUTES.SICHERES_KINO} exact>
                Sicheres Kino?
                </NavLinkItem> */}
              <Nav>
                <NavDropdown
                  title="Flohmarkt"
                  className={styles.DropdownNavLink}
                >
                  <NavDropdown.Item href={ROUTES.EVENT}>
                    Flohmarkt
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.KINDERFLOHMARKT}>
                    Kinderflohmarkt
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_STANDPLATZ_BUCHEN}>
                    Standplatz buchen
                  </NavDropdown.Item>
                  <NavDropdown.Item href={ROUTES.FLOHMARKT_JOBS_AUTOKINO}>
                    Jobs am Flohmarkt
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              {/* <NavLinkItem url={ROUTES.EVENT} exact>
                Flohmarkt
            </NavLinkItem> */}
              <Nav>
                <NavDropdown
                  title="Gutscheine kaufen"
                  className={styles.DropdownNavLink}
                >
                  <NavDropdown.Item href={ROUTES.GIFTCARD}>
                    Kino Gutschein kaufen
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <NavLinkItem url={ROUTES.CART} exact>
                {this.props.cartCount ? (
                  <span className={styles.CartCounter}>
                    {this.props.cartCount}
                  </span>
                ) : null}
                <Icon icon="cart" className={styles.Icon} fontSize={20} />
              </NavLinkItem>
            </Navbar.Collapse>
          </Navbar>
          {/* <div className={styles.PageTitle}>{getPageTitle()}</div> */}
        </header>
      );
    }
  }
}

Header.propTypes = {
  onLogout: PropTypes.func,
  cartCount: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  onShowAuthModal: PropTypes.func,
};

function getCartCount(state) {
  var count = 0;
  if (Object.keys(state.cart.cart) != null) {
    Object.keys(state.cart.cart).forEach(element => {
      if (!element.includes('|')) {
        count++;
      }
    });
  }
  return count;
}

function getPageTitle() {
  const pathname = window.location.href;
  console.log('current path: ' + pathname);
  if (pathname) {
    if (pathname.endsWith('/flohmarkt-wien')) {
      return '#DeinServiceFlohmarkt';
    } else if (
      pathname.endsWith('/flohmarkt-standplaetze-standgebuehren-platzmiete')
    ) {
      return '#FlohmarktStandgebühr';
    } else if (pathname.endsWith('/checklist-fuer-flohmarkt-verkaufer')) {
      return '#ChecklisteFlohmarktaussteller';
    } else if (pathname.endsWith('/faqs-flohmarkt-wien-sonntag')) {
      return '#Fragen+Antworten';
    } else if (pathname.endsWith('/kinderflohmarkt-wien-sonntag')) {
      return '#KinderFlohmarkt';
    } else if (
      pathname.endsWith('/kundenliebe-oesterreichs-bester-flohmarkt')
    ) {
      return '#TopAdresseFuerFlohmarktFans';
    } else if (
      pathname.endsWith(
        '/tipps-tricks-fuer-deinen-erfolgreichen-flohmarkt-verkauf'
      )
    ) {
      return '#TippsFuerVerkauefer';
    } else if (pathname.endsWith('/kinoprogramm-wien')) {
      return '#Kinoprogramm';
    }
  }
  return '';
}

const mapStateToProps = state => {
  return {
    cartCount: getCartCount(state),
    isAuthenticated: state.auth.isAuth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAuthModal: () => dispatch(actions.switchShowAuth()),
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
