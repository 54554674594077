import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  checkoutres: {},
  isLoading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CHECKOUT_START:
      return updateObject(state, {
        isLoading: true,
      });
    case actionTypes.FETCH_CHECKOUT_FAIL:
      return updateObject(state, {
        error: { _status: true },
        isLoading: false,
      });
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return updateObject(state, {
        checkoutres: action.data,
        isLoading: false,
      });

    default:
      return state;
  }
};

export default reducer;
