import { combineReducers } from 'redux';

import notificationReducer from './notifications';
import productListsReducer from './productList';
import showListsReducer from './showList';
import loadingBarReducer from './loadingBar';
import wishlistReducer from './wishlist';
import productReducer from './product';
import errorsReducer from './errors';
import cartReducer from './cart';
import authReducer from './auth';
import showReducer from './show';
import upcomingShowReducer from './upcomingShowList';
import cookieLawReducer from './cookieLaw';
import orderReducer from './order';
import checkoutReducer from './checkout';
import giftCardReducer from './giftCard';
import blogListReducer from './blogList';
import movieReducer from './movie';
import orderStornoReducer from './orderstorno';

const rootReducer = combineReducers({
  notifications: notificationReducer,
  loadingBar: loadingBarReducer,
  lists: productListsReducer,
  wishlist: wishlistReducer,
  product: productReducer,
  errors: errorsReducer,
  cart: cartReducer,
  auth: authReducer,
  shows: showListsReducer,
  show: showReducer,
  cookieLaw: cookieLawReducer,
  order: orderReducer,
  checkout: checkoutReducer,
  giftCard: giftCardReducer,
  upcomingShows: upcomingShowReducer,
  blogList: blogListReducer,
  movie: movieReducer,
  orderstorno: orderStornoReducer,
});

export default rootReducer;
