import React from 'react';
import PropTypes from 'prop-types';

import styles from './ShowThumb.module.css';
import Ribbon from './Ribbon/Ribbon';
import ActionsBox from './ActionsBox/ActionsBox';
import { SHOW, MOVIE_SHOW } from '../../constants/routes';
import PreloadImage from '../UI/PreloadImage/PreloadImage';
import PreloadLinkShow from '../UI/PreloadLink/Show/Show';
import stylesActionBox from '../../components/ShowThumb/ActionsBox/ActionsBox.module.css';
import Kinostyles from '../Util/AutokinoStylesComponent.module.css';

import { connect } from 'react-redux';
import { fetchProductImage } from '../../store/actions';
import { Link } from 'react-router-dom';
import { MOVIE_DETAIL } from '../../constants/routes';

const ShowThumb = props => {
  const {
    item: {
      date,
      location,
      timeSlot,
      movie,
      showName,
      products,
      publicAvailable,
      showId,
      time,
      upcoming,
      ribbonType,
      soldOut,
      futureSales,
      urlIdentifier,
      displayMoviePageBeforeShowDetail,
      movieUrlIdentifier,
      countFutureShowsForMovie,
      fomoStatus,
    },
    handleShow = props.handleShow,
    dateString = props.dateString,
  } = props;

  const {
    inWishlist,
    onAddToCart,
    addingToCart,
    inCart,
    toggleWishlist,
    togglingWishlist,
  } = props;

  const getDetailPath = () => {
    /*return countFutureShowsForMovie > 0
      ? MOVIE_DETAIL + '/' + movieUrlIdentifier
      : urlIdentifier
      ? MOVIE_SHOW + '/' + urlIdentifier
      : SHOW + '/' + showId;*/
    return urlIdentifier
      ? MOVIE_SHOW + '/' + urlIdentifier
      : SHOW + '/' + showId;
  };

  const getFomoText = () => {
    var text = '';
    if (fomoStatus === 1) {
      text = 'Weniger als 20 Tickets verfügbar';
    } else if (fomoStatus === 2) {
      text = 'Weniger als 10 Tickets verfügbar';
    } else if (fomoStatus === 3) {
      text = 'Weniger als 5 Tickets verfügbar';
    } else if (fomoStatus === 4) {
      text = 'Nur noch 2 Tickets verfügbar';
    } else if (fomoStatus === 5) {
      text = 'Nur noch 1 Ticket verfügbar';
    }
    return text;
  };

  return (
    <div id={'show_' + showId} name={'show_' + showId}>
      <div
        className={
          Kinostyles.ListHeadlineCenterUnderline + ' mb-2 mt-2 d-lg-none'
        }
      >
        {dateString}
      </div>
      <div className={Kinostyles.ListMovieTitle}>
        {showName != null ? showName : movie.movieName}
      </div>
      <div>
        <div className={styles.ImageBox}>
          <Ribbon type={ribbonType} />
          {!soldOut && !futureSales && showId !== 207 ? (
            <PreloadLinkShow
              to={getDetailPath()}
              id={showId}
              title={
                showName != null
                  ? showName
                  : movie != null
                  ? movie.movieName
                  : location + ' | ' + date + ' | ' + time
              }
            >
              <PreloadImage
                src={movie != null ? movie.imagePath : ''}
                //src={imagePath}//{imagePath}
                alt={movie.movieName}
                className={styles.Image}
                loadingClass={styles.LoadingImage}
              />
            </PreloadLinkShow>
          ) : (
            <PreloadImage
              src={movie != null ? movie.imagePath : ''}
              //src={imagePath}//{imagePath}
              alt={movie.movieName}
              className={styles.Image}
              loadingClass={styles.LoadingImage}
            />
          )}
        </div>
        <div>
          {!soldOut ? (
            <div className={styles.fomoBox}>{getFomoText()}&nbsp;</div>
          ) : (
            ''
          )}
          <div className={Kinostyles.CenterContent + ' mt-2'}>
            {showId === 207 ? (
              <div className={stylesActionBox.Buttons + ' mt-2 mr-2'}>
                <Link
                  to="./mylittlepony"
                  title="Hol dir dein Ticket"
                  className="btn btn-first"
                >
                  {' '}
                  Hol dir dein Ticket!
                </Link>
              </div>
            ) : !date.endsWith('2099') ? (
              !soldOut ? (
                !futureSales ? (
                  <ActionsBox
                    additionalClassName={styles.ActionsBox}
                    addedToCart={onAddToCart}
                    addingToCart={addingToCart}
                    inCart={inCart}
                    showId={showId}
                    linkTarget={getDetailPath()}
                    urlIdentifier={urlIdentifier}
                    toggledWishlist={toggleWishlist}
                    togglingWishlist={togglingWishlist}
                    inWishlist={inWishlist}
                  />
                ) : !futureSales && countFutureShowsForMovie > 0 ? (
                  <ActionsBox
                    additionalClassName={styles.ActionsBox}
                    addedToCart={onAddToCart}
                    addingToCart={addingToCart}
                    inCart={inCart}
                    showId={showId}
                    linkTarget={MOVIE_DETAIL + '/' + movieUrlIdentifier}
                    urlIdentifier={urlIdentifier}
                    toggledWishlist={toggleWishlist}
                    togglingWishlist={togglingWishlist}
                    inWishlist={inWishlist}
                    linkText="Details zum Film"
                  />
                ) : (
                  <button className="btn btn-first btn-disabled">
                    Verkauf startet in Kürze
                  </button>
                )
              ) : countFutureShowsForMovie > 0 ? (
                <>
                  <button className="btn btn-first btn-disabled">
                    Ausverkauft
                  </button>
                  <br />
                  <br />
                  <ActionsBox
                    additionalClassName={'btn btn-first btn-disabled'}
                    addedToCart={onAddToCart}
                    addingToCart={addingToCart}
                    inCart={inCart}
                    showId={showId}
                    linkTarget={MOVIE_DETAIL + '/' + movieUrlIdentifier}
                    urlIdentifier={urlIdentifier}
                    toggledWishlist={toggleWishlist}
                    togglingWishlist={togglingWishlist}
                    inWishlist={inWishlist}
                    linkText="Weitere Termine"
                  />
                </>
              ) : (
                <button className="btn btn-first btn-disabled">
                  Ausverkauft
                </button>
              )
            ) : (
              <button
                onClick={e => {
                  handleShow(true, movie.movieId);
                }}
                className="btn btn-first"
              >
                Vormerken
              </button>
            )}
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

/*return (
    <div className={styles.Show}>
      <div className={styles.ShowInner}>
        <div className={styles.ImageBox}>
          <Ribbon type={ribbonType} />
          <PreloadLinkShow
            to={`${SHOW}/${showId}`}
            id={showId}
            title={
              movie != null
                ? movie.movieName
                : location + ' | ' + date + ' | ' + time
            }
          >
            <PreloadImage
              src={movie != null ? movie.imagePath : ''}
              //src={imagePath}//{imagePath}
              alt={movie.movieName}
              className={styles.Image}
              loadingClass={styles.LoadingImage}
            />
          </PreloadLinkShow>
        </div>
        <div className={styles.Info}>
          <div>{date}</div>
          <h3 className={styles.Title}>{movie.movieName}</h3>
          <div>Einlass: {timeSlot}</div>
          <div>Start:{time}</div>
        </div>

        <ActionsBox
          additionalClassName={styles.ActionsBox}
          addedToCart={onAddToCart}
          addingToCart={addingToCart}
          inCart={inCart}
          showId={showId}
          toggledWishlist={toggleWishlist}
          togglingWishlist={togglingWishlist}
          inWishlist={inWishlist}
        />
      </div>
    </div>
  );
}; */

ShowThumb.propTypes = {
  inWishlist: PropTypes.bool,
  onAddToCart: PropTypes.func,
  addingToCart: PropTypes.bool,
  inCart: PropTypes.bool,
  toggleWishlist: PropTypes.func,
  togglingWishlist: PropTypes.bool,
  item: PropTypes.object,
  handleShow: PropTypes.func,
  dateString: PropTypes.string,
};

// const mapStateToProps = state => {

//   return {

//   };
// };

export default connect(
  null,
  { fetchProductImage }
)(ShowThumb);
// export default ShowThumb;
