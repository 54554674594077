import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../UI/Button/Button';
import styles from './DiscountCode.module.css';

import { Link } from 'react-router-dom';
import { CART } from '../../../constants/routes';
import Input from '../../UI/Input/Input';

export const DiscountCode = ({ enteredCode, applyCode, disableGutschein }) => {
  return (
    <div className={styles.Container}>
      <div className={styles.TotalPriceBox}>
        <span className={styles.TotalPrice}>Gutscheincode</span>
        <Input
          inputType="input"
          value={enteredCode}
          changed={event => inputChangedHandler(event)}
          invalid={true}
          touched={false}
        />
      </div>
      <Button
        clicked={e =>
          enteredCode !== '' && enteredCode !== undefined
            ? applyCode(enteredCode, e)
            : e.preventDefault()
        }
        theme="big"
        className={['btn', 'btn-first', styles.ButtonCTA].join(' ')}
      >
        <Link
          to={`${CART}`}
          title="Gutschein einlösen"
          className="linkButtonStyle"
          disabled
        >
          Gutschein einlösen
        </Link>
      </Button>
    </div>
  );

  function inputChangedHandler(event) {
    enteredCode = event.target.value;
    disableGutschein = event.target.value !== '';
    console.log('input changed' + enteredCode);
  }
};

DiscountCode.propTypes = {
  disableGutschein: PropTypes.bool,
  applyCode: PropTypes.func,
  enteredCode: PropTypes.string,
};
