import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../UI/Button/Button';
import styles from './Summary.module.css';

import { Link } from 'react-router-dom';
import { CHECKOUT } from '../../../constants/routes';

export const Summary = ({ totalPrice, checkoutClicked, disableCheckout }) => {
  return (
    <div className={styles.Container}>
      <div className={styles.TotalPriceBox}>
        <span className={styles.TotalPrice}>Gesamtsumme</span>
        <span className={styles.TotalPrice}>&euro;{totalPrice}</span>
      </div>
      <Button
        theme="big"
        className={['btn', 'btn-first', styles.ButtonCTA].join(' ')}
        disabled={disableCheckout}
      >
        <Link
          to={`${CHECKOUT}`}
          onClick={checkoutClicked}
          disabled={disableCheckout}
          title="Zur Kassse"
          className="linkButtonStyle"
        >
          Zur Kasse
        </Link>
      </Button>
    </div>
  );
};

Summary.propTypes = {
  totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableCheckout: PropTypes.bool,
  checkoutClicked: PropTypes.func,
};
