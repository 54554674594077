import React from 'react';
import {
  Route,
  Switch,
  withRouter,
  useHistory,
  Redirect,
} from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';

import * as ROUTES from '../../constants/routes';
import lazyComponent from '../../hoc/lazyComponent/lazyComponent';
import LandingPage from '../../pages/LandingPage/LandingPage';

import PrivateRoute from '../PrivateRoute/PrivateRoute';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import GiftCardPage from '../../pages/GiftCardPage/GiftCardPage';

// Import lazyloading components
const LazyProductPage = lazyComponent(() => {
  return import(/* webpackChunkName: "product" */ '../../pages/ProductPage/ProductPage');
});
LazyProductPage.preload();
const LazyWishlistPage = lazyComponent(() => {
  return import(/* webpackChunkName: "wishlist" */ '../../pages/WishlistPage/WishlistPage');
});
const LazyCartPage = lazyComponent(() => {
  return import(/* webpackChunkName: "cart" */ '../../pages/CartPage/CartPage');
});
const LazyNotFoundPage = lazyComponent(() => {
  return import(/* webpackChunkName: "404" */ '../../pages/NotFoundPage/NotFoundPage');
});
const LazyCategoryPage = lazyComponent(() => {
  return import(/* webpackChunkName: "404" */ '../../pages/CategoryPage/CategoryPage');
});
const LazyShowPage = lazyComponent(() => {
  return import(/* webpackChunkName: "show" */ '../../pages/ShowPage/ShowPage');
});
const LazyCheckoutPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/CheckoutPage/CheckoutPage');
});
const LazyPaymentPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/PaymentPage/PaymentPage');
});
const LazyGiftCardPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/GiftCardPage/GiftCardPage');
});
const LazyEventPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/EventPage/EventPage');
});
const LazyUpcomingShowPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/UpcomingShowPage/UpcomingShowPage');
});

const LazyEventListPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/EventList/EventList');
});
const LazyBlogAutokinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/blog/BlogAutokinoPage/BlogAutokinoPage');
});
const LazyBlogFlohmarktPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/blog/BlogFlohmarktPage/BlogFlohmarktPage');
});
const LazyKinderflohmarktPage = lazyComponent(() => {
  return import('../../pages/flohmarkt/KinderflohmarktPage');
});
const LazyGeschichtePage = lazyComponent(() => {
  return import('../../pages/flohmarkt/GeschichtePage');
});
const LazyKundenliebePage = lazyComponent(() => {
  return import('../../pages/flohmarkt/KundenliebePage');
});
const LazyBesuchertippsPage = lazyComponent(() => {
  return import('../../pages/flohmarkt/BesucherTippsPage');
});
const LazyFaqPage = lazyComponent(() => {
  return import('../../pages/flohmarkt/FaqPage');
});
const LazyMarktordnungPage = lazyComponent(() => {
  return import('../../pages/flohmarkt/MarktordnungPage');
});
const LazyChecklistVerkaeuferPage = lazyComponent(() => {
  return import('../../pages/flohmarkt/ChecklistVerkaeuferPage');
});
const LazyTippsVerkaeuferPage = lazyComponent(() => {
  return import('../../pages/flohmarkt/TippsVerkaeuferPage');
});
const LazyAnreisePage = lazyComponent(() => {
  return import('../../pages/flohmarkt/AnreisePage');
});
const LazyFotosPage = lazyComponent(() => {
  return import('../../pages/flohmarkt/FotosPage');
});
const LazyStandplatzgebuehrenPage = lazyComponent(() => {
  return import('../../pages/flohmarkt/StandgebuehrenPage');
});
const LazyBikeFaqPage = lazyComponent(() => {
  return import('../../pages/autokino/BikeFaqPage');
});
const LazyCustomerFeedbackFormPage = lazyComponent(() => {
  return import('../../pages/CustomerFeedbackFormPage/CustomerFeedbackFormPage');
});
const LazySuccessPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/SuccessPage/SuccessPage');
});
const LazyEventLocationPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/EventLocationPage');
});
const LazyAnreiseAutokinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/AnreiseAutokinoPage');
});
const LazyFaqAutokinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/FaqAutokinoPage');
});
const LazyHaustierePage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/HaustierePage');
});
const LazyJobsPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/JobsPage');
});
const LazyPhilosophiePage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/PhilosophiePage');
});
const LazyOpenAirKinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/OpenAirKinoPage');
});
const LazySiegerKinotestPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/SiegerKinotestPage');
});
const LazyAutokinoPartnerPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/AutokinoPartnerPage');
});
const LazyDasAutokinoPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/autokino/DasAutokinoPage');
});
const LazyHomePage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/HomePage/HomePage');
});
const LazyMoviePage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/MoviePage/MoviePage');
});
const LazyFlohmarktMistelbachPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/flohmarkt/FlohmarktMistelbachPage');
});
const LazyFlohmarktJobsPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/flohmarkt/FlohmarktJobsPage');
});
const LazyFlohmarktNiederoesterreichPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/flohmarkt/FlohmarktNiederoesterreichPage');
});
const LazyFlohmarktGaenserndorfPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/flohmarkt/FlohmarktGaenserndorfPage');
});
const LazyShowNotFoundPage = lazyComponent(() => {
  return import(/* webpackChunkName: "checkout" */ '../../pages/ShowNotFoundPage/ShowNotFoundPage');
});
const LazyOrderStornoPage = lazyComponent(() => {
  return import('../../pages/OrderStornoPage/OrderStornoPage');
});

const LazyLandingPageMinions4Page = lazyComponent(() => {
  return import('../../pages/autokino/LandingPageMinions4Page');
});

const LazyGenericLandingPageMinions4Page = lazyComponent(() => {
  return import('../../pages/autokino/landingpages/GenericTestPage');
});

const RouteContainer = posed.div({
  enter: { opacity: 1, x: '0%' },
  exit: { opacity: 0, x: '-100%' },
});
const trackPageView = () => {
  console.log('PageView Event', window.location.pathname);
  ReactPixel.pageView(window.location.pathname);
};
const Routes = props => {
  const history = useHistory();
  ReactGA.send('pageview');
  if (window.location.pathname === '/cart') {
    ReactGA.event('begin_checkout', {});
  }
  React.useEffect(() => {
    trackPageView(); // To track the first pageview upon load
    history.listen(trackPageView); // To track the subsequent pageviews
    if (window.location.pathname === '/cart') {
      ReactPixel.track('InitiateCheckout');
    }
  }, [history]);

  return (
    <PoseGroup>
      <RouteContainer key={props.location.key + 'asd'}>
        <Switch location={props.location}>
          <Route
            path={ROUTES.LANDING}
            exact
            component={LandingPage}
            key="landing"
          />

          <Route
            path={`${ROUTES.GENERIC_LANDING_PAGE}`}
            component={LazyGenericLandingPageMinions4Page}
            exact
            key="genericLandingPage"
          />
          <Route
            path={`${ROUTES.GIFTCARD}`}
            component={LazyGiftCardPage}
            exact
            key="giftCart"
          />
          <Route
            path={`${ROUTES.EVENT}`}
            component={LazyEventListPage}
            exact
            key="flohmarkt"
          />
          <Route path={`${ROUTES.AUTOMARKT}`} exact>
            <Redirect to="/" />
          </Route>
          <Route
            path={`${ROUTES.BLOGAUTOKINO}`}
            component={LazyBlogAutokinoPage}
            exact
            key="Blog Autokino"
          />
          <Route
            path={`${ROUTES.BLOGFLOHMARKT}`}
            component={LazyBlogFlohmarktPage}
            exact
            key="Blog Flohmarkt"
          />
          <Route
            path={`${ROUTES.UPCOMING_MOVIES}`}
            component={LazyUpcomingShowPage}
            exact
            key="upcomingmovies"
          />
          <Route
            path={`${ROUTES.EVENT_ITEM}/:id`}
            component={LazyEventPage}
            key="event"
          />
          <Route
            path={`${ROUTES.SHOW}/:id`}
            component={LazyShowPage}
            key="show"
          />
          <Route
            path={`${ROUTES.MOVIE_SHOW}/:name`}
            component={LazyShowPage}
            key="show"
          />
          <Route
            path={`${ROUTES.EVENT_FLOHMARKT_DETAIL}/:name`}
            component={LazyEventPage}
            key="event"
          />
          <Route
            path={`${ROUTES.PUBLIC_EVENT_DETAIL}/:name`}
            component={LazyEventPage}
            key="event"
          />
          <Route
            path={`${ROUTES.MOVIE_DETAIL}/:name`}
            component={LazyMoviePage}
            key="movie"
          />
          <Route
            path={`${ROUTES.ORDER_STORNO}/:name`}
            component={LazyOrderStornoPage}
            key="orderStorno"
          />
          <Route
            path={ROUTES.CATEGORY}
            component={LazyCategoryPage}
            key="category"
          />
          <Route
            path={`${ROUTES.KINDERFLOHMARKT}`}
            component={LazyKinderflohmarktPage}
            exact
            key="Kinderflohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_GESCHICHTE}`}
            component={LazyGeschichtePage}
            exact
            key="Geschichte Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_KUNDENLIEBE}`}
            component={LazyKundenliebePage}
            exact
            key="Kundenliebe Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_BESUCHER_TIPPS}`}
            component={LazyBesuchertippsPage}
            exact
            key="Besuchertipps Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_FAQ}`}
            component={LazyFaqPage}
            exact
            key="Fragen und Antworten zum Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.AUTOKINO_BIKE_FAQ}`}
            component={LazyBikeFaqPage}
            exact
            key="Fragen und Antworten zu Kinotickets für Fahrrad und Motorrad im Autokino Wien"
          />
          <Route
            path={`${ROUTES.MINIONS4_AUTOKINO}`}
            component={LazyLandingPageMinions4Page}
            exact
            key="Die Minions 4 im Autokino Wien"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_MARKTORDNUNG}`}
            component={LazyMarktordnungPage}
            exact
            key="Marktordnung Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_CHECKLIST}`}
            component={LazyChecklistVerkaeuferPage}
            exact
            key="Checklist Verkäufer Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_VERKAEUFER_TIPPS}`}
            component={LazyTippsVerkaeuferPage}
            exact
            key="Tipps Verkäufer Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_ANREISE}`}
            component={LazyAnreisePage}
            exact
            key="Anreise Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_FOTOS}`}
            component={LazyFotosPage}
            exact
            key="Fotos Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_STANDGEBUEHREN}`}
            component={LazyStandplatzgebuehrenPage}
            exact
            key="Standplatzgebühren Flohmarkt Autokino"
          />
          <Route
            path={`${ROUTES.CUSTOMER_FEEDBACK_FORM}`}
            component={LazyCustomerFeedbackFormPage}
            exact
            key="Deine Meinung zählt"
          />
          <Route
            path={`${ROUTES.CUSTOMER_FEEDBACK_FORM_SUCCESS}`}
            component={LazySuccessPage}
            exact
            key="Das hat geklappt!"
          />
          <Route
            path={`${ROUTES.EVENT_LOCATION}`}
            component={LazyEventLocationPage}
            exact
            key="Eventlocation Autokino Wien"
          />
          <Route
            path={`${ROUTES.ANREISE_AUTOKINO}`}
            component={LazyAnreiseAutokinoPage}
            exact
            key="Anreise Autokino Wien"
          />
          <Route
            path={`${ROUTES.FAQ_AUTOKINO}`}
            component={LazyFaqAutokinoPage}
            exact
            key="FAQ Autokino Wien"
          />
          <Route
            path={`${ROUTES.HAUSTIERE_AUTOKINO}`}
            component={LazyHaustierePage}
            exact
            key="FAQ Autokino Wien"
          />
          <Route
            path={`${ROUTES.JOBS_AUTOKINO}`}
            component={LazyJobsPage}
            exact
            key="Jobs Autokino Wien"
          />
          <Route
            path={`${ROUTES.PHILOSOPHIE_AUTOKINO}`}
            component={LazyPhilosophiePage}
            exact
            key="Philosophie Autokino Wien"
          />
          <Route
            path={`${ROUTES.OPENAIR_AUTOKINO}`}
            component={LazyOpenAirKinoPage}
            exact
            key="Open Air Kino Autokino Wien"
          />
          <Route
            path={`${ROUTES.SIEGERKINOTEST_AUTOKINO}`}
            component={LazySiegerKinotestPage}
            exact
            key="Sieger Kinotest 2022"
          />
          <Route
            path={`${ROUTES.PARTNER_AUTOKINO}`}
            component={LazyAutokinoPartnerPage}
            exact
            key="Partner Autokino Wien"
          />
          <Route
            path={`${ROUTES.DAS_AUTOKINO}`}
            component={LazyDasAutokinoPage}
            exact
            key="Das Autokino Wien"
          />
          <Route
            path={`${ROUTES.HOME}`}
            component={LazyHomePage}
            exact
            key="Das Autokino Wien"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_MISTELBACH}`}
            component={LazyFlohmarktMistelbachPage}
            exact
            key="Flohmarkt Mistelbach"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_JOBS_AUTOKINO}`}
            component={LazyFlohmarktJobsPage}
            exact
            key="Flohmarkt Jobs"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_NIEDEROESTERREICH}`}
            component={LazyFlohmarktNiederoesterreichPage}
            exact
            key="Flohmarkt Niederoesterreich"
          />
          <Route
            path={`${ROUTES.FLOHMARKT_GAENSERNDORF}`}
            component={LazyFlohmarktGaenserndorfPage}
            exact
            key="Flohmarkt Gänserndorf"
          />
          <Route
            path={`${ROUTES.SHOW_NOT_FOUND}`}
            component={LazyShowNotFoundPage}
            exact
            key="Vorstellung nicht gefunden"
          />
          <Route
            path={`${ROUTES.ITEM}/:id`}
            component={LazyProductPage}
            key="product"
          />
          <Route path="/giftcard" exact>
            <Redirect to="/kinogutschein" />
          </Route>
          <Route path="/programm" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/booking" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/home" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/index" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/index.html" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/index.php" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/home.php" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/home.html" exact>
            <Redirect to="/" />
          </Route>
          <Route
            path="/news-events"
            exact
            component={LandingPage}
            key="landing"
          />

          <PrivateRoute
            path={ROUTES.CART}
            component={LazyCartPage}
            key="cart"
          />
          <PrivateRoute
            path={ROUTES.WISHLIST}
            component={LazyWishlistPage}
            key="wishlist"
          />
          <Route
            path="/privacy"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/impressum-datenschutz/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/facebook"
            component={() => {
              window.location.replace('https://www.facebook.com/autokino.at/');
              return null;
            }}
          />
          <Route
            path="/facebookflohmarkt"
            component={() => {
              window.location.replace(
                'https://www.facebook.com/FlohmarktImAutokinoWien'
              );
              return null;
            }}
          />
          <Route
            path="/facebookkinderflohmarkt"
            component={() => {
              window.location.replace(
                'https://www.facebook.com/KinderflohmarktWienAutokino'
              );
              return null;
            }}
          />
          <Route
            path="/instagram"
            component={() => {
              window.location.replace(
                'https://www.instagram.com/autokinowien/'
              );
              return null;
            }}
          />
          <Route
            path="/instagramflohmarkt"
            component={() => {
              window.location.replace(
                'https://www.instagram.com/flohmarkt_im_autokino_wien/'
              );
              return null;
            }}
          />
          <Route
            path="/instagramkinderflohmarkt"
            component={() => {
              window.location.replace(
                'https://www.instagram.com/kinderflohmarkt_wien_autokino/'
              );
              return null;
            }}
          />
          <Route
            path="/sicheres-kino"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/home-2/sicheres-kino/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/agb"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/agb/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/dein-event-im-autokino"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/dein-event-im-autokino/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/deinevent"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/dein-event-im-autokino/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/aboutus"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/geschichte/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/team"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/team-partner/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/contact"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/kontakt/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/contactprint"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/kontakt/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/kontakt"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/kontakt/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/geschichte"
            component={() => {
              window.location.replace(
                'https://autokino.at/kino/kontakt/index.html'
              );
              return null;
            }}
          />
          <Route
            path="/traktorkino"
            component={() => {
              window.location.replace('https://autokino.at/event/134');
              return null;
            }}
          />
          <Route
            path="/chanukka2021"
            component={() => {
              window.location.replace('https://autokino.at/event/233');
              return null;
            }}
          />
          <Route
            path="/mylittlepony"
            component={() => {
              window.location.replace(
                'https://www.ticket-regional.de/events_info.php?eventID=181509'
              );
              return null;
            }}
          />

          <PrivateRoute
            path={ROUTES.CHECKOUT}
            component={LazyCheckoutPage}
            key="checkout"
          />

          <Route
            path={`${ROUTES.PAYMENT}/:id`}
            component={LazyPaymentPage}
            key="payment"
          />

          <Route component={LazyNotFoundPage} key="404" />
        </Switch>
      </RouteContainer>
    </PoseGroup>
  );
};

export default withRouter(Routes);
