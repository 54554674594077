import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  movie: null,
  loading: false,
  error: false,
  forceReload: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MOVIE_DETAIL_START:
      return updateObject(state, { loading: true });
    case actionTypes.FETCH_MOVIE_DETAIL_FAIL:
      return updateObject(state, {
        error: true,
        loading: false,
      });
    case actionTypes.FETCH_MOVIE_DETAIL_SUCCESS:
      return updateObject(state, {
        movie: action.movie,
        //selItems: action.defaultProducts,
        loading: false,
      });

    default:
      return state;
  }
};

export default reducer;
