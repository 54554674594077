import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import headerImage2 from '../../assets/images/flohmarkt/flohmarkt3-3.jpeg';

// Styles
import styles from './GiftCardContentHeader.module.css';

import * as actions from '../../store/actions';
import { Col, Container, Image, Row } from 'react-bootstrap';
import ResponsiveWrapper from '../UI/ResponsiveWrapper/ResponsiveWrapper';
import { Link } from 'react-router-dom';

class GiftCardContentHeader extends Component {
  render() {
    return (
      <div>
        <div className={styles.Header}>
          <ResponsiveWrapper>
            <div className={styles.HeaderTextContainer}>
              <div className={styles.HeadlineSmall}>
                Du suchst ein außergewöhnliches Geschenk?
                <br />
                <br />
              </div>
            </div>
          </ResponsiveWrapper>
        </div>
      </div>
    );
  }
}

GiftCardContentHeader.propTypes = {
  onLogout: PropTypes.func,
  cartCount: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  onShowAuthModal: PropTypes.func,
};

function getCartCount(state) {
  var count = 0;
  if (Object.keys(state.cart.cart) != null) {
    Object.keys(state.cart.cart).forEach(element => {
      if (!element.includes('|')) {
        count++;
      }
    });
  }
  return count;
}

const mapStateToProps = state => {
  return {
    cartCount: getCartCount(state),
    isAuthenticated: state.auth.isAuth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAuthModal: () => dispatch(actions.switchShowAuth()),
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardContentHeader);
