import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  show: null,
  loading: false,
  error: false,
  selItems: {},
  totPrice: 0,
  forceReload: false,
  showProductSet: false,
  voucherItems: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SHOW_START:
      return updateObject(state, { loading: true, showProductSet: false });
    case actionTypes.FETCH_SHOW_FAIL:
      return updateObject(state, {
        error: true,
        loading: false,
        showProductSet: false,
      });
    case actionTypes.FETCH_SHOW_SUCCESS:
      console.log(
        'JSON.stringify(state.show.selItems) = ' +
          JSON.stringify(action.defaultProducts)
      );
      return updateObject(state, {
        show: action.show,
        selItems: action.defaultProducts,
        loading: false,
        showProductSet: true,
      });
    case actionTypes.SHOW_PRODUCT_SUCCESS:
      console.log('SHOW_PRODUCT_SUCCESS = ' + action.valueFlag);
      return updateObject(state, {
        loading: true,
        showProductSet: action.valueFlag,
      });

    case actionTypes.PROCESS_PURCHASE_START:
      return updateObject(state, { loading: true, showProductSet: false });
      break;

    case actionTypes.PROCESS_PURCHASE_FAIL:
      return updateObject(state, { loading: true, showProductSet: false });
      break;
    case actionTypes.SHOW_APPLY_DISCOUNT_CODE_SUCCESS:
      console.log(
        'JSON.stringify(action.voucherItems) = ' +
          JSON.stringify(action.voucherItems)
      );
      return updateObject(state, {
        voucherItems: action.voucherItems,
        isRemovingFromCart: { state, [action.id]: false },
      });

    case actionTypes.PROCESS_PURCHASE_SUCCESS:
      // let show = state.show;
      //let products = state.show.products;

      if (
        state.show.productPackages != null &&
        state.show.productPackages.length > 0
      ) {
        console.log('found show with multiple packages');
        state.show.productPackages.forEach(workArr => {
          existingProduct = workArr.products.find(element => {
            return (
              element.purchaseProductId === action.product.purchaseProductId
            );
          });

          indexOfItem = workArr.products.indexOf(element => {
            return (
              element.purchaseProductId === action.product.purchaseProductId
            );
          });
          console.log('indexOfItem = ' + indexOfItem);

          // state.show.products.push(action.product);
          if (existingProduct == null) {
            if (action.product.count !== 0) {
              workArr.products.push(action.product);
            }
          } else {
            console.log('Existing item = ' + JSON.stringify(existingProduct));

            if (action.product.count === 0) {
              console.log('remove product2');
              workArr.products = workArr.products.filter(x => {
                return x.purchaseProductId !== action.product.purchaseProductId;
              });
            } else {
              existingProduct = action.product;
            }
          }
          console.log('object will be updated');
        });
        return updateObject(state, {
          show: state.show,
          // selItems: selItems,
          showProductSet: true,
        });
      } else {
        console.log(JSON.stringify(state.show));
        var existingProduct = state.show.products.find(element => {
          return element.purchaseProductId === action.product.purchaseProductId;
        });

        var indexOfItem = state.show.products.indexOf(element => {
          return element.purchaseProductId === action.product.purchaseProductId;
        });
        console.log('indexOfItem = ' + indexOfItem);

        // state.show.products.push(action.product);
        if (existingProduct == null) {
          if (action.product.count !== 0) {
            state.show.products.push(action.product);
          }
        } else {
          console.log('Existing item = ' + JSON.stringify(existingProduct));

          if (action.product.count === 0) {
            console.log('remove product2');
            state.show.products = state.show.products.filter(x => {
              return x.purchaseProductId !== action.product.purchaseProductId;
            });
          } else {
            existingProduct = action.product;
          }
        }
        console.log('update will be updated');
        return updateObject(state, {
          show: state.show,
          // selItems: selItems,
          showProductSet: true,
        });
      }

    default:
      return state;
  }
};

export default reducer;
