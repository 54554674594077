import * as actionTypes from './actionTypes';
import { addError } from './errors';
import { showLoadingBar, hideLoadingBar } from './loadingBar';
import API from '../../services/WsApi';
import * as ROUTES from '../../constants/routes';
import { productID } from '../../utils/generateID';
import CartPage from '../../pages/CartPage/CartPage';
import { addNotification } from './notifications';

export const fetchShowStart = (id, identifier) => {
  return {
    type: actionTypes.FETCH_SHOW_START,
  };
};

export const fetchShowFail = () => {
  return {
    type: actionTypes.FETCH_SHOW_FAIL,
  };
};

export const fetchShowSuccess = (show, defaultProducts) => {
  return {
    type: actionTypes.FETCH_SHOW_SUCCESS,
    show,
    defaultProducts,
  };
};

export const proccessPurchaseStart = id => {
  return {
    type: actionTypes.PROCESS_PURCHASE_START,
  };
};

export const proccessPurchaseFail = () => {
  return {
    type: actionTypes.PROCESS_PURCHASE_FAIL,
  };
};

export const showProductSetSuccess = valueFlag => {
  console.log('!!!!!! showProductSetSuccess called value: ' + valueFlag);
  return {
    type: actionTypes.SHOW_PRODUCT_SUCCESS,
    valueFlag,
  };
};

export const proccessPurchaseSuccess = product => {
  return {
    type: actionTypes.PROCESS_PURCHASE_SUCCESS,
    product,
  };
};

export const proccessPurchase = (id, data) => async (dispatch, getState) => {
  dispatch(proccessPurchaseStart());

  try {
    console.log('onProccessPurchase = ' + id);

    var dataItems = getState().show.show.products;

    if (
      getState().show.show.productPackages != null &&
      getState().show.show.productPackages.length > 0
    ) {
      console.log('found show with multiple packages');
      getState().show.show.productPackages.forEach(workArr => {
        var itemFound = workArr.products.find(y => {
          console.log(
            'checking for id: ' + y.purchaseProductId + ' compare: ' + id
          );
          return y.purchaseProductId === id;
        });
        console.log('found item: ' + itemFound + ' id: ' + id);
        if (itemFound != null) {
          dataItems = workArr.products;
        }
      });
    }

    Object.values(data).forEach(x => {
      var foundedItem = dataItems.find(y => {
        console.log(
          'checking for id: ' +
            y.purchaseProductId +
            ' compare: ' +
            x.purchaseProductId
        );
        return y.purchaseProductId === x.purchaseProductId;
      });
      if (foundedItem != null) {
        foundedItem.count = x.amount;
      }
    });

    var body = {
      purchaseProductId: id,
      purchaseProducts: dataItems,
    };

    console.log('purchaseOrder = ' + JSON.stringify(body));

    API.post('product/getDiscountProduct', body)
      .then(function(response) {
        var resultData = response.data;
        console.log('response.data = ' + resultData);
        dispatch(proccessPurchaseSuccess(response.data.product));
      })
      .catch(function(error) {
        console.log('proccessPurchase failed' + error);
        dispatch(proccessPurchaseFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('proccessPurchase finally');
        // dispatch(hideLoadingBar(showId));
      });
  } catch (error) {
    console.log('proccessPurchase error' + error);
    dispatch(proccessPurchaseFail());
    dispatch(addError());
    // dispatch(hideLoadingBar(showId));
  }
};

export const showProductSet = value => async (dispatch, getState) => {
  dispatch(showProductSetSuccess(value));
};

export const fetchShow = (showId, identifier) => async (dispatch, getState) => {
  if (
    getState().show.show &&
    (getState().show.show.showId === showId ||
      getState().show.show.urlIdentifier === identifier)
  ) {
    return;
  }
  dispatch(fetchShowStart());
  dispatch(showLoadingBar(showId ? showId : identifier));
  try {
    console.log('fetch show id = ' + showId + ' identifier: ' + identifier);
    if (!showId) {
      showId = 0;
    }
    if (!identifier) {
      identifier = '';
    }
    API.get(
      'show/getShowDetailData?showid=' +
        showId +
        '&showidentifier=' +
        identifier
    )
      .then(function(response) {
        console.log('response.data = ' + response.data.location);
        if (
          response.data != null &&
          (response.data.movie != null || response.data.event != null)
        ) {
          let defProducts = {};
          if (
            response.data.products != null &&
            response.data.products.length > 0
          ) {
            response.data.products.forEach(item => {
              if (item.mandatory) {
                console.log('item DONE');
                item.count = 1;
                defProducts = { [item.purchaseProductId]: item };
              }
            });
          }

          dispatch(fetchShowSuccess(response.data, defProducts));
        } else {
          console.log(
            'no result returned for fetchShow, show invalid show page'
          );
          window.location.href = ROUTES.SHOW_NOT_FOUND;
        }
      })
      .catch(function(error) {
        console.log('fetch show failed' + error);
        dispatch(fetchShowFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchShowEnd');
        dispatch(hideLoadingBar(showId ? showId : identifier));
      });
  } catch (error) {
    console.log('fetch show failed');
    dispatch(fetchShowFail());
    dispatch(addError());
    dispatch(hideLoadingBar(showId ? showId : identifier));
  }
};

export const showApplyDiscountCodeStart = id => {
  return {
    type: actionTypes.SHOW_APPLY_DISCOUNT_CODE_START,
    id,
  };
};

export const showApplyDiscountCodeFail = id => {
  return {
    type: actionTypes.SHOW_APPLY_DISCOUNT_CODE_FAIL,
    id,
  };
};

export const showApplyDiscountCodeSuccess = voucherItems => {
  return {
    type: actionTypes.SHOW_APPLY_DISCOUNT_CODE_SUCCESS,
    voucherItems,
  };
};

export const showApplyDiscountCode = order => async (dispatch, getState) => {
  dispatch(showApplyDiscountCodeStart(order));

  try {
    let cart;
    let id = '';

    API.post('voucher/validateVoucher', order)
      .then(function(response) {
        var resultData = response.data;

        if (
          resultData !== null &&
          resultData.voucherItems !== null &&
          resultData.voucherItems.length > 0
        ) {
          /*cart = getState().cart.cart;

          resultData.voucherItems.forEach(product => {
            id = productID(5);
            console.log('addto showCart item with Id = ' + id);

            // add show
            if (cart[id] != null) {
              cart[id].amount += product.amount;
              let oldPrice =
                Math.abs(cart[id].totalPrice) + Math.abs(product.totalPrice);
              cart[id].totalPrice = +oldPrice.toFixed(2);
            } else {
              product.key = id;
              product.order = 'ZZZZZZZZ';
              cart[id] = product;
              cart[id].totalPrice = product.count * product.price;
              cart[id].amount = product.count;
              cart[id].voucherCode = product.voucherCode;
              cart[id].voucherCategory = product.voucherCategory;
            }
          });*/

          //let total = CartPage.calcTotalPrice(cart);
          //console.log('totalin applyDiscount = ' + total);
          // window.location.reload();
          dispatch(
            addNotification('Gutschein wurde dem Warenkorb hinzugefügt 🛒!')
          );
          dispatch(showApplyDiscountCodeSuccess(resultData.voucherItems));
        } else {
          dispatch(
            addNotification('Gutschein ungültig 🛒!' + resultData.errorMessage)
          );
        }
      })
      .catch(function(error) {
        console.log('applyDiscountCode');
        dispatch(addError());
      })
      .finally(function() {
        console.log('applyDiscountCode');
      });
  } catch (error) {
    console.log('error= ' + error);
    dispatch(addError());
  }
};
