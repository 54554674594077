import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  blogs: [],
  isLoading: {},
  error: false,
};

const reducer = (state = initialState, action) => {
  const { listID: id } = action;
  switch (action.type) {
    case actionTypes.FETCH_BLOGS_START:
      return updateObject(state, {
        isLoading: { ...state.isLoading, [id]: true },
      });
    case actionTypes.FETCH_BLOGS_FAIL:
      return updateObject(state, {
        error: { _status: true },
        isLoading: { ...state.isLoading, [id]: false },
      });
    case actionTypes.FETCH_BLOGS_SUCCESS:
      if (action != null) {
        console.log(
          'fetch blogs success , blogs = ' + JSON.stringify(action.blogs)
        );
      } else {
        action.blogs = {};
      }
      return updateObject(state, {
        blogs: action.blogs,
        isLoading: { ...state.isLoading, [id]: false },
      });
    default:
      return state;
  }
};

export default reducer;
