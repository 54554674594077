import * as actionTypes from './actionTypes';
import { addError } from './errors';

import API from '../../services/WsApi';

export const fetchGiftCardStart = () => {
  return {
    type: actionTypes.FETCH_GIFTCARD_START,
  };
};

export const fetchGiftCardFail = error => {
  return {
    type: actionTypes.FETCH_GIFTCARD_FAIL,
  };
};

export const fetchGiftCardSuccess = giftCards => {
  return {
    type: actionTypes.FETCH_GIFTCARD_SUCCESS,
    giftCards,
  };
};

export const fetchGiftCard = () => async (dispatch, getState) => {
  dispatch(fetchGiftCardStart());

  try {
    API.get('voucher/getAvailableVouchers')
      .then(function(response) {
        dispatch(fetchGiftCardSuccess(response.data.products));
      })
      .catch(function(error) {
        dispatch(fetchGiftCardFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchListEnd');
      });
  } catch (error) {
    dispatch(fetchGiftCardFail());
    dispatch(addError());
  }
};
