import * as actionTypes from './actionTypes';
import { addError } from './errors';
import API from '../../services/WsApi';
import cookie from 'react-cookies';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

export const fetchCheckoutStart = () => {
  return {
    type: actionTypes.FETCH_CHECKOUT_START,
  };
};

export const fetchCheckoutFail = error => {
  return {
    type: actionTypes.FETCH_CHECKOUT_FAIL,
  };
};

export const fetchCheckoutSuccess = data => {
  // window.location.reload();
  return {
    type: actionTypes.FETCH_CHECKOUT_SUCCESS,
    data,
  };
};

export const fetchCheckout = id => async (dispatch, getState) => {
  dispatch(fetchCheckoutStart());
  console.log('fetchCheckoutStart');
  try {
    console.log('fetchcheckout start id = ' + id);
    API.get('order/paymentStatus?orderid=' + id)
      .then(function(response) {
        console.log(
          'fetchCheckout process response = ' + response.data.paymentStatus
        );
        if (response.data.paymentStatus === 'paid') {
          localStorage.setItem('cart', '{}');
          cookie.save('cart', '{}');
          //cookie.remove('cart');
          ReactGA.event('purchase', {
            value: response.data.amount,
            currency: response.data.currency,
            transaction_id: id,
          });
          ReactPixel.track('Purchase', {
            value: response.data.amount,
            currency: response.data.currency,
          });
        }
        dispatch(fetchCheckoutSuccess(response.data));
      })
      .catch(function(error) {
        console.log('fetchCheckoutFail');
        dispatch(fetchCheckoutFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchCheckoutEnd');
      });
  } catch (error) {
    dispatch(fetchCheckoutFail());
    dispatch(addError());
  }
};
