import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  consentAccept: false,
  prefAccept: false,
  statAccept: false,
  markAccept: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COOKIELAW_CONSENT_GIVEN:
      return updateObject(state, { consentAccept: true });

    case actionTypes.COOKIELAW_ACCEPT_PREF:
      return updateObject(state, { prefAccept: true });

    case actionTypes.COOKIELAW_ACCEPT_STAT:
      return updateObject(state, { statAccept: true });

    case actionTypes.COOKIELAW_ACCEPT_MARK:
      return updateObject(state, { markAccept: true });

    case actionTypes.COOKIELAW_DECLINE_PREF:
      return updateObject(state, { prefAccept: false });

    case actionTypes.COOKIELAW_DECLINE_STAT:
      return updateObject(state, { statAccept: false });

    case actionTypes.COOKIELAW_DECLINE_MARK:
      return updateObject(state, { markAccept: false });

    default:
      return state;
  }
};

export default reducer;
