import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  giftCards: [],
  isLoading: false,
  error: false,
  selGiftCardsAmount: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GIFTCARD_START:
      return updateObject(state, {
        isLoading: true,
      });
    case actionTypes.FETCH_GIFTCARD_FAIL:
      return updateObject(state, {
        error: { _status: true },
        isLoading: false,
      });
    case actionTypes.FETCH_GIFTCARD_SUCCESS:
      return updateObject(state, {
        giftCards: action.giftCards,
        isLoading: false,
      });

    default:
      return state;
  }
};

export default reducer;
