import * as actionTypes from './actionTypes';
import { addError } from './errors';
import { addNotification } from './notifications';
import cookie from 'react-cookies';
import API from '../../services/WsApi';
import { productID } from '../../utils/generateID';
import CartPage from '../../pages/CartPage/CartPage';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

let unsubscribeListener = null;

export const addToCartStart = id => {
  return {
    type: actionTypes.ADD_TO_CART_START,
    id,
  };
};

export const addToCartFail = (error, id) => {
  return {
    type: actionTypes.ADD_TO_CART_FAIL,
    id,
  };
};

export const addToCartSuccess = (id, total) => {
  return {
    type: actionTypes.ADD_TO_CART_SUCCESS,
    id,
    total,
  };
};

export const addToCart = item => async (dispatch, getState) => {
  let id = 0;
  console.log('addtocart, item id = ' + id);
  ReactPixel.track('AddToCart', { productId: id });
  ReactGA.event('add_to_cart', { productId: id });
  let cart;
  try {
    cart = getState().cart.cart;

    item.forEach(product => {
      id = product.key;
      console.log(
        'addto cart item with Id = ' +
          id +
          'discountPurchaseProductId = ' +
          product.discountPurchaseProductId
      );
      dispatch(addToCartStart(id));

      // add show
      if (cart[id] != null) {
        cart[id].amount += product.amount;
        let oldPrice =
          Math.abs(cart[id].totalPrice) + Math.abs(product.totalPrice);
        cart[id].totalPrice = +oldPrice.toFixed(2);
      } else {
        cart[id] = product;
      }

      localStorage.setItem('cart', JSON.stringify(cart));
      cookie.save('cart', JSON.stringify(cart));
      let total = CartPage.calcTotalPrice(cart);

      dispatch(addToCartSuccess(id, total));
    });

    dispatch(addNotification('Produkt wurde dem Warenkorb hinzugefügt 🛒!'));
  } catch (error) {
    dispatch(addToCartFail(id));
    dispatch(addError());
  }
};

export const removeFromCartStart = id => {
  return {
    type: actionTypes.REMOVE_FROM_CART_START,
    id,
  };
};

export const removeFromCartFail = id => {
  return {
    type: actionTypes.REMOVE_FROM_CART_FAIL,
    id,
  };
};

export const removeFromCartSuccess = (newData, total) => {
  return {
    type: actionTypes.REMOVE_FROM_CART_SUCCESS,
    newData,
    total,
  };
};

export const updateCartStart = id => {
  return {
    type: actionTypes.UPDATE_CART_START,
    id,
  };
};

export const updateCartFail = id => {
  return {
    type: actionTypes.UPDATE_CART_FAIL,
    id,
  };
};

export const updateCartSuccess = (newData, total) => {
  return {
    type: actionTypes.UPDATE_CART_SUCCESS,
    newData,
    total,
  };
};

export const updateCart = (newData, total) => async (dispatch, getState) => {
  dispatch(updateCartStart(newData));
  // let cart;

  try {
    console.log(
      'removefrom cart id = ' + newData + 'Json = ' + JSON.stringify(newData)
    );
    dispatch(updateCartSuccess(newData, total));

    localStorage.setItem('cart', JSON.stringify(newData));
    cookie.save('cart', JSON.stringify(newData));
  } catch (error) {
    console.log('error= ' + error);
    dispatch(updateCartFail(newData));
    dispatch(addError());
  }
};

export const removeFromCart = (newData, total) => async (
  dispatch,
  getState
) => {
  dispatch(removeFromCartStart(newData));
  // let cart;

  try {
    console.log(
      'removefrom cart id = ' + newData + 'JSON = ' + JSON.stringify(newData)
    );
    dispatch(removeFromCartSuccess(newData, total));
    localStorage.setItem('cart', JSON.stringify(newData));
    cookie.save('cart', JSON.stringify(newData));
    dispatch(addNotification('Produkt wurde entfernt 🛒!'));
  } catch (error) {
    console.log('error= ' + error);
    dispatch(removeFromCartFail(newData));
    dispatch(addError());
  }
};

export const applyDiscountCodeStart = id => {
  return {
    type: actionTypes.APPLY_DISCOUNT_CODE_START,
    id,
  };
};

export const applyDiscountCodeFail = id => {
  return {
    type: actionTypes.APPLY_DISCOUNT_CODE_FAIL,
    id,
  };
};

export const applyDiscountCodeSuccess = (data, total) => {
  return {
    type: actionTypes.APPLY_DISCOUNT_CODE_SUCCESS,
    data,
    total,
  };
};

export const applyDiscountCode = order => async (dispatch, getState) => {
  dispatch(applyDiscountCodeStart(order));

  try {
    let cart;
    let id = '';

    API.post('voucher/validateVoucher', order)
      .then(function(response) {
        var resultData = response.data;

        if (
          resultData !== null &&
          resultData.voucherItems !== null &&
          resultData.voucherItems.length > 0
        ) {
          cart = getState().cart.cart;

          resultData.voucherItems.forEach(product => {
            id = productID(5);
            console.log('addto cart item with Id = ' + id);
            dispatch(addToCartStart(id));

            // add show
            if (cart[id] != null) {
              cart[id].amount += product.amount;
              let oldPrice =
                Math.abs(cart[id].totalPrice) + Math.abs(product.totalPrice);
              cart[id].totalPrice = +oldPrice.toFixed(2);
            } else {
              product.key = id;
              product.order = 'ZZZZZZZZ';
              cart[id] = product;
              cart[id].totalPrice = product.count * product.price;
              cart[id].amount = product.count;
              cart[id].voucherCode = product.voucherCode;
              cart[id].voucherCategory = product.voucherCategory;
            }
          });

          let total = CartPage.calcTotalPrice(cart);
          console.log('totalin applyDiscount = ' + total);
          localStorage.setItem('cart', JSON.stringify(cart));
          cookie.save('cart', JSON.stringify(cart));
          dispatch(applyDiscountCodeSuccess(cart, total));
          // window.location.reload();
          dispatch(
            addNotification('Gutschein wurde dem Warenkorb hinzugefügt 🛒!')
          );
        } else {
          dispatch(applyDiscountCodeFail(order));
          dispatch(
            addNotification('Gutschein ungültig 🛒!' + resultData.errorMessage)
          );
        }
      })
      .catch(function(error) {
        console.log('applyDiscountCode');
        dispatch(applyDiscountCodeFail(order));
        dispatch(addError());
      })
      .finally(function() {
        console.log('applyDiscountCode');
      });
  } catch (error) {
    console.log('error= ' + error);
    dispatch(applyDiscountCodeFail(order));
    dispatch(addError());
  }
};

export const subscribeCartStart = () => {
  return {
    type: actionTypes.SUBSCRIBE_CART_START,
  };
};

export const subscribeCartFail = () => {
  return {
    type: actionTypes.SUBSCRIBE_CART_FAIL,
  };
};

export const subscribeCartSuccess = cart => {
  return {
    type: actionTypes.SUBSCRIBE_CART_SUCCESS,
    cart,
  };
};

export const subscribeCart = cart => (dispatch, getState) => {
  dispatch(subscribeCartStart());

  if (unsubscribeListener) {
    unsubscribeListener();
  }

  try {
    const cart = getState().cart.cart;
    dispatch(subscribeCartSuccess(cart));
    console.log('subscribet cart productType = ' + cart[1].productType);

    // const uid = getState().auth.uid;
    // unsubscribeListener = firestoreRefs.cart
    //   .doc(uid)
    //   .collection('items')
    //   .onSnapshot(querySnapshot => {
    //     const cart = {};
    //     querySnapshot.forEach(doc => {
    //       cart[doc.data().id] = doc.data();
    //     });
    //     dispatch(subscribeCartSuccess(cart));
    //   });
  } catch (error) {
    dispatch(subscribeCartFail());
    dispatch(addError());
  }
};

export const unsubscribeCart = () => {
  if (unsubscribeListener) {
    localStorage.remove('cart');
    cookie.remove('cart', { path: '/' });
    unsubscribeListener();
  }
  return {
    type: actionTypes.UNSUBSCRIBE_CART,
  };
};
