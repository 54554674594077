import * as actionTypes from './actionTypes';
import { addError } from './errors';
import API from '../../services/WsApi';

export const fetchBlogsStart = () => {
  return {
    type: actionTypes.FETCH_BLOGS_START,
  };
};

export const fetchBlogsFail = error => {
  return {
    type: actionTypes.FETCH_BLOGS_FAIL,
  };
};

export const fetchBlogsSuccess = blogs => {
  console.log('!!!fetchBlogsSuccess');

  return {
    type: actionTypes.FETCH_BLOGS_SUCCESS,
    blogs,
  };
};

export const fetchBlogs = blogType => async (dispatch, getState) => {
  dispatch(fetchBlogsStart());
  console.log('fetchBlogsStart');
  try {
    API.get('blog/getBlogs' + (blogType != null ? '?blogtype=' + blogType : ''))
      .then(function(response) {
        console.log('FetchBlogs process response: ' + JSON.stringify(response));
        dispatch(fetchBlogsSuccess(response.data));
      })
      .catch(function(error) {
        console.log('fetchBlogsFail');
        dispatch(fetchBlogsFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchBlogsEnd');
      });
  } catch (error) {
    dispatch(fetchBlogsFail());
    dispatch(addError());
  }
};
