export const LANDING = 'Kinoprogramm – Autokino Wien #ErlebnisAutokino';
export const CART = 'Warenkorb 🛒';
export const WISHLIST = 'Your Wishlist ❤️';
export const NOT_FOUND = 'Seite wurde nicht gefunden 🤦🏻‍';
export const CHECKOUT = 'Kassa';
export const PAYMENT = 'Bezahlung';
export const PAYMENT_SUCCESSFULL = 'Bezahlung 🖖';
export const OK_MESS = '🖖';
export const GIFTCARD = 'Kinogutschein Autokino 🎁 Jetzt Freude schenken!';
export const FLOHMARKT =
  'Flohmarkt Wien im Autokino 😍 Dein Service Flohmarkt ';
export const AUTOMARKT = 'Automarkt Wien 🛒🚗';
export const UPCOMINGMOVIES = 'Demnächst im Kino – Autokino Wien';
export const CUSTOMER_FEEDBACK_TITLE = 'Deine Meinung zählt 🖖';
export const SAVE_DATA = 'Daten wurden gespeichert';
export const HOME = 'Kino I Flohmarkt I Eventlocation – #MehrAlsNurKino';
