import * as actionTypes from './actionTypes';
import { addError } from './errors';
import { addNotification } from './notifications';
import API from '../../services/WsApi';

export const fetchUpcomingShowsStart = () => {
  return {
    type: actionTypes.FETCH_UPCOMING_SHOWS_START,
  };
};

export const fetchUpcomingShowsFail = error => {
  return {
    type: actionTypes.FETCH_UPCOMING_SHOWS_FAIL,
  };
};

export const fetchUpcomingShowsSuccess = (movies, genres) => {
  console.log('!!!fetchUpcomingShowsSuccess');

  return {
    type: actionTypes.FETCH_UPCOMING_SHOWS_SUCCESS,
    movies,
    genres,
  };
};

export const saveMovieReminderSubscriptionStart = () => {
  return {
    type: actionTypes.SAVE_MOVIE_REMINDER_SUBSCRIPTION_START,
  };
};

export const saveMovieReminderSubscriptionFail = error => {
  return {
    type: actionTypes.SAVE_MOVIE_REMINDER_SUBSCRIPTION_FAIL,
  };
};

export const saveMovieReminderSubscriptionSuccess = () => {
  console.log('!!!saveMovieReminderSubscriptionSuccess');

  return {
    type: actionTypes.SAVE_MOVIE_REMINDER_SUBSCRIPTION_SUCCESS,
  };
};

export const saveMovieReminderSubscription = requestData => async (
  dispatch,
  getState
) => {
  console.log('in saveMovieReminderSubscription');
  dispatch(saveMovieReminderSubscriptionStart());
  console.log('saveMovieReminderSubscriptionStart');
  try {
    API.post('movie/saveNewsletterMovieReminder', requestData)
      .then(function(response) {
        console.log('saveNewsletterMovieReminder process response');
        dispatch(saveMovieReminderSubscriptionSuccess());
        if (requestData.genres && requestData.genres.length > 0) {
          dispatch(
            addNotification(
              'Wir haben deine Emailadresse gespeichert. Sobald wir einen Film deines Lieblingsgenres spielen, erhältst du eine Erinnerung von uns. Bis bald im Autokino Wien!'
            )
          );
        } else {
          dispatch(
            addNotification(
              'Wir haben deine Emailadresse gespeichert. Sobald wir deine Lieblingsfilme spielen, erhältst du eine Erinnerung von uns. Bis bald im Autokino Wien!'
            )
          );
        }
      })
      .catch(function(error) {
        console.log('saveMovieReminderSubscriptionFail');
        dispatch(saveMovieReminderSubscriptionFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('saveMovieReminderSubscriptionEnd');
      });
  } catch (error) {
    dispatch(saveMovieReminderSubscriptionFail());
    dispatch(addError());
  }
};

export const fetchUpcomingShows = type => async (dispatch, getState) => {
  dispatch(fetchUpcomingShowsStart());
  console.log('fetchUpcomingShowsStart');
  try {
    API.get('movie/getUpcomingMovies')
      .then(function(response) {
        console.log('fetchUpcomingShows process response');
        dispatch(
          fetchUpcomingShowsSuccess(
            response.data.dataEntries,
            response.data.genreEntries
          )
        );
      })
      .catch(function(error) {
        console.log('fetchUpcomingShowsFail');
        dispatch(fetchUpcomingShowsFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchUpcomingShowsEnd');
      });
  } catch (error) {
    dispatch(fetchUpcomingShowsFail());
    dispatch(addError());
  }
};
