export const HOME = process.env.PUBLIC_URL + '/';
export const LANDING = process.env.PUBLIC_URL + '/kinoprogramm-wien';
export const LANDING_HEADER_SECTION =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/?selectshow=headerprogram';
export const CATEGORY = process.env.PUBLIC_URL + '/category';
export const ITEM = process.env.PUBLIC_URL + '/item';
export const SHOW = process.env.PUBLIC_URL + '/show';
export const MOVIE_SHOW =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/kinofilm';
export const MOVIE_DETAIL =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/kinoprogramm';
export const EVENT_ITEM = process.env.PUBLIC_URL + '/event';
export const CART = process.env.PUBLIC_URL + '/cart';
export const WISHLIST = process.env.PUBLIC_URL + '/wishlist';
export const NOT_FOUND = process.env.PUBLIC_URL + '/404';
export const CHECKOUT = process.env.PUBLIC_URL + '/checkout';
export const PAYMENT = process.env.PUBLIC_URL + '/pspresult';
export const GIFTCARD = process.env.PUBLIC_URL + '/kinogutschein-kaufen';
export const EVENT = process.env.PUBLIC_URL + '/flohmarkt-wien';
export const EVENT_FLOHMARKT_DETAIL =
  process.env.PUBLIC_URL + '/flohmarktprogramm-wien';
export const PUBLIC_EVENT_DETAIL = process.env.PUBLIC_URL + '/veranstaltungen';
export const AUTOMARKT = process.env.PUBLIC_URL + '/automarkt';
export const AGB = process.env.PUBLIC_URL + '/agb';
export const SHOW_NOT_FOUND =
  process.env.PUBLIC_URL + '/vorstellung-nicht-gefunden';
export const UPCOMING_MOVIES =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/demnaechst-im-kino';
export const UPCOMING_MOVIES_GENRE =
  process.env.PUBLIC_URL +
  '/kinoprogramm-wien/demnaechst-im-kino?movietype=genres';
export const UPCOMING_MOVIES_OV =
  process.env.PUBLIC_URL + '/kinoprogramm-wien/demnaechst-im-kino?movietype=OV';
export const SICHERES_KINO = process.env.PUBLIC_URL + '/sicheres-kino';
export const CHANUKKA = process.env.PUBLIC_URL + '/chanukka2021';
export const BLOGAUTOKINO = process.env.PUBLIC_URL + '/blog-autokino';
export const BLOGFLOHMARKT = process.env.PUBLIC_URL + '/flohmarkt-wien/blog';
export const KINDERFLOHMARKT =
  process.env.PUBLIC_URL + '/flohmarkt-wien/kinderflohmarkt-wien-sonntag';
export const FLOHMARKT_GESCHICHTE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/geschichte-flohmarkt-autokino';
export const FLOHMARKT_KUNDENLIEBE =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/kundenliebe-oesterreichs-bester-flohmarkt';
export const FLOHMARKT_BESUCHER_TIPPS =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/tipps-und-tricks-fuer-flohmarkt-besucher';
export const FLOHMARKT_VERKAEUFER_TIPPS =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/tipps-tricks-fuer-deinen-erfolgreichen-flohmarkt-verkauf';
export const FLOHMARKT_FAQ =
  process.env.PUBLIC_URL + '/flohmarkt-wien/faqs-flohmarkt-wien-sonntag';
export const FLOHMARKT_MARKTORDNUNG =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-regeln-marktordnung';
export const FLOHMARKT_CHECKLIST =
  process.env.PUBLIC_URL + '/flohmarkt-wien/checklist-fuer-flohmarkt-verkaufer';
export const FLOHMARKT_ANREISE =
  process.env.PUBLIC_URL + '/flohmarkt-wien/anreise-gratis-parken';
export const FLOHMARKT_FOTOS =
  process.env.PUBLIC_URL + '/flohmarkt-wien/flohmarkt-heute-fotos';
export const FLOHMARKT_STANDGEBUEHREN =
  process.env.PUBLIC_URL +
  '/flohmarkt-wien/flohmarkt-standplaetze-standgebuehren-platzmiete';
export const FLOHMARKT_STANDPLATZ_BUCHEN =
  process.env.PUBLIC_URL + '/flohmarkt-wien/?selectshow=headerprogram';
export const AUTOKINO_BIKE_FAQ =
  process.env.PUBLIC_URL +
  '/autokino-wien/fragen-antworten-kinobesucher-fahrrad-faqs';
export const CUSTOMER_FEEDBACK_FORM = process.env.PUBLIC_URL + '/feedback';
export const CUSTOMER_FEEDBACK_FORM_SUCCESS =
  process.env.PUBLIC_URL + '/feedback_success';
export const FLOHMARKT_MISTELBACH =
  process.env.PUBLIC_URL + '/flohmarkt-mistelbach';
export const FLOHMARKT_JOBS_AUTOKINO =
  process.env.PUBLIC_URL + '/flohmarkt-wien/jobs';
export const FLOHMARKT_NIEDEROESTERREICH =
  process.env.PUBLIC_URL + '/flohmarkt-niederoesterreich';
export const FLOHMARKT_GAENSERNDORF =
  process.env.PUBLIC_URL + '/flohmarkt-gaenserndorf';

export const EVENT_LOCATION = process.env.PUBLIC_URL + '/eventlocation';
export const ANREISE_AUTOKINO = process.env.PUBLIC_URL + '/kino/anreise';
export const FAQ_AUTOKINO = process.env.PUBLIC_URL + '/kino/faqs';
export const HAUSTIERE_AUTOKINO =
  process.env.PUBLIC_URL + '/kino/hund-katze-haustiere';
export const JOBS_AUTOKINO = process.env.PUBLIC_URL + '/kino/jobs';
export const PHILOSOPHIE_AUTOKINO =
  process.env.PUBLIC_URL + '/kino/philosophie';
export const OPENAIR_AUTOKINO = process.env.PUBLIC_URL + '/kino/open-air-kino';
export const SIEGERKINOTEST_AUTOKINO =
  process.env.PUBLIC_URL + '/kino/sieger-kinotest2022';
export const PARTNER_AUTOKINO = process.env.PUBLIC_URL + '/kino/partner';
export const DAS_AUTOKINO = process.env.PUBLIC_URL + '/kino-erlebnis';

export const GENERIC_LANDING_PAGE =
  process.env.PUBLIC_URL + '/test-landing-page';

export const ORDER_STORNO = process.env.PUBLIC_URL + '/bestellung-stornieren';

export const MINIONS4_AUTOKINO =
  process.env.PUBLIC_URL +
  '/kinoprogramm-minions-4-ich_einfach_unverbesserlich_4';
