import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  lists: {},
  categories: [],
  isLoading: {},
  error: false,
};

const reducer = (state = initialState, action) => {
  const { listID: id } = action;
  switch (action.type) {
    case actionTypes.FETCH_LIST_START:
      return updateObject(state, {
        isLoading: { ...state.isLoading, [id]: true },
      });
    case actionTypes.FETCH_LIST_FAIL:
      return updateObject(state, {
        error: { _status: true },
        isLoading: { ...state.isLoading, [id]: false },
      });
    case actionTypes.FETCH_LIST_SUCCESS:
      console.log(
        'fetch list success id = ' +
          [id] +
          ', products = ' +
          action.products.length
      );
      return updateObject(state, {
        lists: { ...state.lists, [id]: action.products },
        isLoading: { ...state.isLoading, [id]: false },
      });

    case actionTypes.FETCH_CATEGORIES_START:
      return updateObject(state, {
        isLoading: { ...state.isLoading },
      });
    case actionTypes.FETCH_CATEGORIES_FAIL:
      return updateObject(state, {
        error: { _status: true },
        isLoading: { ...state.isLoading },
      });
    case actionTypes.FETCH_CATEGORIES_SUCCESS:
      console.log(
        'reducer fetch success' +
          action.categories.length +
          ',lists= ' +
          state.lists
      );

      return updateObject(state, {
        lists: { ...state.lists },
        isLoading: { ...state.isLoading },
        categories: action.categories,
      });

    default:
      return state;
  }
};

export default reducer;
