import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  orderstorno: null,
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_STORNO_START:
      return updateObject(state, { loading: true });
    case actionTypes.FETCH_ORDER_STORNO_FAIL:
      return updateObject(state, {
        error: true,
        loading: false,
      });
    case actionTypes.FETCH_ORDER_STORNO_SUCCESS:
      return updateObject(state, {
        orderstorno: action.orderstorno,
        loading: false,
      });
    default:
      return state;
  }
};

export default reducer;
