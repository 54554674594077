import * as actionTypes from './actionTypes';
import { addError } from './errors';
import { showLoadingBar, hideLoadingBar } from './loadingBar';
import API from '../../services/WsApi';

export const fetchMovieDetailStart = (id, identifier) => {
  return {
    type: actionTypes.FETCH_MOVIE_DETAIL_SUCCESS,
  };
};

export const fetchMovieDetailFail = () => {
  return {
    type: actionTypes.FETCH_MOVIE_DETAIL_FAIL,
  };
};

export const fetchMovieDetailSuccess = movie => {
  return {
    type: actionTypes.FETCH_MOVIE_DETAIL_SUCCESS,
    movie,
  };
};

export const fetchMovieDetail = (movieId, identifier) => async (
  dispatch,
  getState
) => {
  if (
    getState().movie.movie &&
    (getState().movie.movie.movieId === movieId ||
      getState().movie.movie.urlIdentifier === identifier)
  ) {
    return;
  }
  dispatch(fetchMovieDetailStart());
  dispatch(showLoadingBar(movieId ? movieId : identifier));
  try {
    console.log('fetch movie id = ' + movieId + ' identifier: ' + identifier);
    if (!movieId) {
      movieId = 0;
    }
    if (!identifier) {
      identifier = '';
    }
    API.get(
      'movie/getMovieDetailData?movieid=' +
        movieId +
        '&movieidentifier=' +
        identifier
    )
      .then(function(response) {
        console.log('response.data = ' + JSON.stringify(response.data));

        dispatch(fetchMovieDetailSuccess(response.data));
      })
      .catch(function(error) {
        console.log('fetch movie failed' + error);
        dispatch(fetchMovieDetailFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchMovieDetailEnd');
        dispatch(hideLoadingBar(movieId ? movieId : identifier));
      });
  } catch (error) {
    console.log('fetch movie detail failed');
    dispatch(fetchMovieDetailFail());
    dispatch(addError());
    dispatch(hideLoadingBar(movieId ? movieId : identifier));
  }
};
