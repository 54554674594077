import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './ActionsBox.module.css';
import Kinostyles from '../../../components/Util/AutokinoStylesComponent.module.css';

const ActionsBox = props => {
  const {
    additionalClassName,
    addedToCart,
    addingToCart,
    inCart,
    inWishlist,
    toggledWishlist,
    togglingWishlist,
    showId,
    linkTarget,
    linkText,
  } = props;

  const showMore = (
    <button
      onClick={null}
      className={Kinostyles.CTAButton}
      disabled={addingToCart}
    >
      <Link
        to={linkTarget}
        title={linkText ? linkText : 'Tickets &amp; Infos'}
        className={Kinostyles.CTAButtonLink}
      >
        {linkText ? linkText : 'Tickets & Infos'}
      </Link>
    </button>
  );

  const buyTicket = (
    <button onClick={null} className={styles.AddToCart} disabled={addingToCart}>
      <Link
        to={linkTarget}
        title={linkText ? linkText : 'Ticket kaufen'}
        className="btn btn-first"
      >
        {' '}
        {linkText ? linkText : 'Ticket kaufen'}
      </Link>
    </button>
  );

  return <div className={styles.Buttons}>{showMore}</div>;
  /*{buyTicket}*/
};

ActionsBox.propTypes = {
  additionalClassName: PropTypes.string,
  addedToCart: PropTypes.func,
  addingToCart: PropTypes.bool,
  inCart: PropTypes.bool,
  inWishlist: PropTypes.bool,
  toggledWishlist: PropTypes.func,
  togglingWishlist: PropTypes.bool,
  showId: PropTypes.number,
  linkTarget: PropTypes.string,
  linkText: PropTypes.string,
};

export default ActionsBox;
