export default {
  baseURL: 'https://autokino.at/autokinobackend/rest/',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers':
      'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
  },
  withCredentials: true,
  auth: {
    username: 'autokino_frontend',
    password: 'hCDP8:VLH:pt~7F$',
  },
};
