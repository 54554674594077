import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  movies: [],
  isLoading: {},
  test: 'yes',
  error: false,
  saveReminderFinished: false,
};

const reducer = (state = initialState, action) => {
  const { listID: id } = action;
  switch (action.type) {
    case actionTypes.FETCH_UPCOMING_SHOWS_START:
      return updateObject(state, {
        isLoading: { ...state.isLoading, [id]: true },
      });
    case actionTypes.FETCH_UPCOMING_SHOWS_FAIL:
      return updateObject(state, {
        error: { _status: true },
        isLoading: { ...state.isLoading, [id]: false },
      });
    case actionTypes.FETCH_UPCOMING_SHOWS_SUCCESS:
      if (action.movies != null) {
        console.log(
          'fetch upcoming show success , shows = ' + action.movies.length
        );
      } else {
        action.movies = {};
      }
      if (action.genres != null) {
        console.log(
          'fetch upcoming show success , genres = ' + action.genres.length
        );
      } else {
        action.genres = {};
      }
      return updateObject(state, {
        movies: action.movies,
        genres: action.genres,
        isLoading: { ...state.isLoading, [id]: false },
      });
    case actionTypes.SAVE_MOVIE_REMINDER_SUBSCRIPTION_SUCCESS:

    default:
      return state;
  }
};

export default reducer;
