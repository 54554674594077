import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import GiftCardThumb from '../../components/GiftCardThumb/GiftCardThumb';
import styles from './GiftCardList.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import { Container } from 'react-bootstrap';

const AnimatedContainer = posed.ul({
  enter: {
    opacity: 1,
    staggerChildren: 50,
    beforeChildren: true,
  },
  leave: {
    opacity: 0,
    staggerChildren: 20,
    staggerDirection: -1,
  },
  initialPose: 'enter',
});

const AnimatedChild = posed.li({
  enter: {
    x: '0%',
    y: 0,
    opacity: 1,
  },
  leave: {
    x: '-100%',
    y: 80,
    opacity: 0,
  },
});

const GiftCardList = props => {
  const addToCartHandler = (e, item) => {
    console.log('add to cart clicked item id = ' + item.productName);
    e.preventDefault();
    props.onAddToCart(e, item);
  };

  const incCounterHandler = (e, id) => {
    console.log('inc clicked item id = ' + id);
    props.incCounterClicked(e, id);
  };

  const decCounterHandler = (e, id) => {
    console.log('dec clicked item id = ' + id);
    props.decCounterClicked(e, id);
  };

  const onCounterChangeHandler = (e, id) => {
    console.log('on change clicked item id = ' + id);
    props.onCounterChangeHandler(e, id);
  };

  const renderCards = () => {
    return props.giftCards.map(p => {
      const id = p.purchaseProductId;
      console.log(
        'render Cards in List, id = ' +
          id +
          'productId = ' +
          p.productName +
          'amount = ' +
          (props.amounts != null && props.amounts[id] != null
            ? props.amounts[id]
            : 1)
      );
      const childItem = (
        <AnimatedChild className={styles.Column} key={id}>
          <GiftCardThumb
            item={p}
            selAmount={
              props.amounts != null && props.amounts[id] != null
                ? props.amounts[id]
                : 1
            }
            onAddToCart={e => addToCartHandler(e, p)}
            incCounterClicked={e => incCounterHandler(e, id)}
            decCounterClicked={e => decCounterHandler(e, id)}
            onCounterChange={e => onCounterChangeHandler(e, id)}
          />
        </AnimatedChild>
      );

      return childItem;
    });
  };

  // render the Spinner initially
  let productsList = <Spinner />;
  // render the real component if
  // products are received and valid
  productsList = (
    <AnimatedContainer pose="enter" className={styles.Row}>
      {renderCards()}
    </AnimatedContainer>
  );

  return <div>{productsList}</div>;
};

GiftCardList.propTypes = {
  giftCards: PropTypes.arrayOf(PropTypes.object),
  onAddToCart: PropTypes.func,
  amounts: PropTypes.object,
  addDefaultProducts: PropTypes.func,
  incCounterClicked: PropTypes.func,
  decCounterClicked: PropTypes.func,
  onCounterChange: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    giftCards: state.giftCard.giftCards,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardList);
