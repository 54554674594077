import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  shows: [],
  events: [],
  isLoading: {},
  error: false,
};

const reducer = (state = initialState, action) => {
  const { listID: id } = action;
  switch (action.type) {
    case actionTypes.FETCH_SHOWS_START:
      return updateObject(state, {
        isLoading: { ...state.isLoading, [id]: true },
      });
    case actionTypes.FETCH_SHOWS_FAIL:
      return updateObject(state, {
        error: { _status: true },
        isLoading: { ...state.isLoading, [id]: false },
      });
    case actionTypes.FETCH_SHOWS_SUCCESS:
      if (action.shows != null) {
        console.log('fetch show success , shows = ' + action.shows.length);
      } else {
        action.shows = {};
      }
      return updateObject(state, {
        shows: action.shows,
        isLoading: { ...state.isLoading, [id]: false },
      });
    case actionTypes.FETCH_PUBLIC_EVENTS_SUCCESS:
      if (action.publicEvents != null) {
        console.log(
          'fetch public events success , events = ' + action.publicEvents.length
        );
      } else {
        action.publicEvents = {};
      }
      return updateObject(state, {
        events: action.publicEvents,
        isLoading: { ...state.isLoading, [id]: false },
      });

    default:
      return state;
  }
};

export default reducer;
