import * as actionTypes from './actionTypes';
import { addError } from './errors';

export const fetchProductImageStart = imageId => {
  return {
    type: actionTypes.FETCH_PRODUCT_IMAGE_START,
    imageId,
  };
};

export const fetchProductImageFail = imageId => {
  return {
    type: actionTypes.FETCH_PRODUCT_IMAGE_FAIL,
    imageId,
  };
};

export const fetchProductImageSuccess = (imageId, imgUrl) => {
  console.log(
    'fetchProductImageSuccess, id = ' + imageId + ', imgUrl = ' + imgUrl
  );
  return {
    type: actionTypes.FETCH_PRODUCT_IMAGE_SUCCESS,
    imgUrl,
    imageId,
  };
};

export const fetchProductImage = (id, imagePath) => async (
  dispatch,
  getState
) => {
  console.log('fetchProductImage, id = ' + id + ' imageUrl: ' + imagePath);
  try {
    dispatch(fetchProductImageStart(id));
    dispatch(fetchProductImageSuccess(id, imagePath));
  } catch (error) {
    dispatch(fetchProductImageFail(id));
    dispatch(addError());
  }
};
