import React from 'react';

import styles from './Footer.module.css';
import Column from './Column/Column';
import { getClassName } from '../UI/ResponsiveWrapper/ResponsiveWrapper';
import * as ROUTES from '../../constants/routes';

/**
 * TODO
 * Implement select elements
 * Render the links from the database
 */

const Footer = () => {
  let facebookurl = '/facebook';
  let instaurl = '/instagram';
  var columns = [];
  if (window.location.pathname.includes('flohmarkt')) {
    if (window.location.pathname.includes('kinderflohmarkt')) {
      facebookurl = '/facebookkinderflohmarkt';
      instaurl = '/instagramkinderflohmarkt';
    } else {
      facebookurl = '/facebookflohmarkt';
      instaurl = '/instagramflohmarkt';
    }

    columns = [
      {
        title: 'SOCIAL MEDIA',
        links: [
          {
            title: 'Facebook',
            desc: 'Facebook',
            url: facebookurl,
            extern: true,
          },
          {
            title: 'Instagram',
            desc: 'Instagram',
            url: instaurl,
            extern: true,
          },
        ],
      },
      {
        title: 'ÜBER UNS',
        links: [
          {
            title: 'Kinderflohmarkt',
            desc: 'Kinderflohmarkt im Autokino Wien',
            url: ROUTES.KINDERFLOHMARKT,
          },
          {
            title: 'Blog Flohmarkt',
            desc: 'Blog Flohmarkt',
            url: ROUTES.BLOGFLOHMARKT,
          },
        ],
      },
      {
        title: 'KONTAKT',
        links: [
          {
            title: 'Dein Event im Autokino',
            desc: 'Dein Event im Autokino',
            url: ROUTES.EVENT_LOCATION,
          },
        ],
      },
      {
        title: 'IMPRINT',
        links: [
          {
            title: 'Impressum',
            desc: 'Impressum',
            url: '/privacy',
          },
        ],
      },
    ];
  } else {
    columns = [
      {
        title: 'SOCIAL MEDIA',
        links: [
          {
            title: 'Facebook',
            desc: 'Facebook',
            url: facebookurl,
            extern: true,
          },
          {
            title: 'Instagram',
            desc: 'Instagram',
            url: instaurl,
            extern: true,
          },
        ],
      },
      {
        title: 'ÜBER UNS',
        links: [
          {
            title: 'Tickets kaufen',
            desc: 'Tickets kaufen',
            url: '/',
          },
          {
            title: 'Blog Autokino',
            desc: 'Blog Autokino',
            url: '/blog-autokino',
          },
        ],
      },
      {
        title: 'KONTAKT',
        links: [
          {
            title: 'Kontakt aufnehmen',
            desc: 'Kontakt aufnehmen',
            url: '/contact',
          },
          {
            title: 'Dein Event im Autokino',
            desc: 'Dein Event im Autokino',
            url: ROUTES.EVENT_LOCATION,
          },
        ],
      },
      {
        title: 'IMPRINT',
        links: [
          {
            title: 'Impressum',
            desc: 'Impressum',
            url: '/privacy',
          },
        ],
      },
    ];
  }

  const cols = columns.map((e, i) => {
    return <Column title={e.title} links={e.links} key={i} />;
  });
  return (
    <div className={[styles.Footer, getClassName()].join(' ')}>{cols}</div>
  );
};

export default Footer;
