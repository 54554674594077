import * as actionTypes from './actionTypes';
import { addError } from './errors';
import { showLoadingBar, hideLoadingBar } from './loadingBar';
import API from '../../services/WsApi';
import { addNotification } from './notifications';

export const fetchOrderStornoStart = (id, identifier) => {
  return {
    type: actionTypes.FETCH_ORDER_STORNO_START,
  };
};

export const fetchOrderStornoFail = () => {
  return {
    type: actionTypes.FETCH_ORDER_STORNO_FAIL,
  };
};

export const fetchOrderStornoSuccess = orderstorno => {
  return {
    type: actionTypes.FETCH_ORDER_STORNO_SUCCESS,
    orderstorno,
  };
};

export const fetchOrderForStorno = stornoIdentifier => async (
  dispatch,
  getState
) => {
  if (
    getState().orderstorno &&
    getState().orderstorno.stornoIdentifier === stornoIdentifier
  ) {
    return;
  }

  dispatch(fetchOrderStornoStart());
  dispatch(showLoadingBar(stornoIdentifier));
  try {
    console.log('fetch orderstorno identifier: ' + stornoIdentifier);
    API.get('order/getOrderForStorno?stornoIdentifier=' + stornoIdentifier)
      .then(function(response) {
        console.log('response.data = ' + response.data.orderHeadId);
        if (response.data != null && response.data.orderHeadId != null) {
          dispatch(fetchOrderStornoSuccess(response.data));
        } else {
          console.log(
            'no result returned for orderstorno, invalid stornoIdentifier'
          );
        }
      })
      .catch(function(error) {
        console.log('fetch orderstorno failed' + error);
        dispatch(fetchOrderStornoFail());
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchOrderStornoEnd');
        dispatch(hideLoadingBar(stornoIdentifier));
      });
  } catch (error) {
    console.log('fetch orderstorno failed');
    dispatch(fetchOrderStornoFail());
    dispatch(addError());
    dispatch(hideLoadingBar(stornoIdentifier));
  }
};

export const processOrderStornoStart = (id, identifier) => {
  return {
    type: actionTypes.PROCESS_ORDER_STORNO_START,
  };
};

export const processOrderStornoFail = () => {
  return {
    type: actionTypes.PROCESS_ORDER_STORNO_FAIL,
  };
};

export const processOrderStornoSuccess = orderstorno => {
  return {
    type: actionTypes.PROCESS_ORDER_STORNO_SUCCESS,
    orderstorno,
  };
};

export const processOrderStorno = orderHeadId => async (dispatch, getState) => {
  dispatch(fetchOrderStornoStart());
  dispatch(showLoadingBar(orderHeadId));
  try {
    console.log('processOrderStorno orderHeadId: ' + orderHeadId);
    API.get('order/orderStorno?orderHeadId=' + orderHeadId)
      .then(function(response) {
        console.log('response.data = ' + response.data.success);
        if (response.data != null && response.data.success) {
          dispatch(
            addNotification(
              'Deine Bestellung wurde storniert. Die Rückerstattung erfolgt auf die gewählte Zahlungsmethode!'
            )
          );
        } else {
          dispatch(
            addNotification(
              'Beim stornieren der Bestellung ist ein Fehler aufgetreten, bitte probiere es später noch einmal'
            )
          );
        }
      })
      .catch(function(error) {
        console.log('processOrderStorno failed' + error);
        dispatch(
          addNotification(
            'Beim stornieren der Bestellung ist ein Fehler aufgetreten, bitte probiere es später noch einmal'
          )
        );
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchOrderStornoEnd');
        dispatch(hideLoadingBar(orderHeadId));
      });
  } catch (error) {
    console.log('fetch orderstorno failed');
    dispatch(
      addNotification(
        'Beim stornieren der Bestellung ist ein Fehler aufgetreten, bitte probiere es später noch einmal'
      )
    );
    dispatch(addError());
    dispatch(hideLoadingBar(orderHeadId));
  }
};
