import React from 'react';

import * as ROUTES from '../../../../constants/routes';
import * as APP_CONST from '../../../../constants/appConsts';
import styles from './TableConfigs.module.css';
import './style.global.css';
import Icon from '../../../UI/Icon/Icon';
import Counter from '../../../UI/Counter/Counter';
import Spinner from '../../../UI/Spinner/Spinner';
import PreloadLinkProduct from '../../../UI/PreloadLink/Product/Product';

const ProductCell = ({ row }) => (
  <div className={styles.FlexCell}>
    <div className={styles.CellLeft}>
      {row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW ||
      row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT ||
      row.original.productType === APP_CONST.PRODUCT_TYPE_VOUCHER ? (
        <div className={['show-wrapper', styles.InfoWrapper].join(' ')}>
          <div className={styles.ThumbnailBox}>
            <PreloadLinkProduct
              to={
                row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW
                  ? `${ROUTES.SHOW}/${row.original.show.showId}`
                  : row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT
                  ? `${ROUTES.EVENT_ITEM}/${row.original.show.showId}`
                  : `${ROUTES.GIFTCARD}`
              }
              id={
                row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW ||
                row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT
                  ? row.original.show.showId
                  : row.original.id
              }
              title={
                row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW
                  ? row.original.movie.movieName
                  : row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT
                  ? row.original.event.eventName
                  : row.original.product.productName
              }
            >
              <img
                className={styles.Thumbnail}
                src={
                  row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW
                    ? row.original.movie.imagePath
                    : row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT
                    ? row.original.event.thumbImage //row.original.event.thumbImage
                    : row.original.product.productImagePath
                }
                alt={
                  row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW
                    ? row.original.movie.imageName
                    : row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT
                    ? row.original.event.eventName
                    : row.original.product.productName
                }
              />
            </PreloadLinkProduct>
          </div>
          <div className={styles.PreloadLinkWrapper}>
            <PreloadLinkProduct
              to={
                row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW
                  ? `${ROUTES.SHOW}/${row.original.show.showId}`
                  : row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT
                  ? `${ROUTES.EVENT_ITEM}/${row.original.show.showId}`
                  : `${ROUTES.GIFTCARD}`
              }
              id={
                row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW ||
                row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT
                  ? row.original.show.showId
                  : row.original.product.id
              }
              title={
                row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW
                  ? row.original.movie.movieName
                  : row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT
                  ? row.original.event.eventName
                  : row.original.product.productName
              }
              className={styles.Title}
            >
              {row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW ? (
                <span>
                  {row.original.movie.movieName} {row.original.show.date}{' '}
                  {row.original.show.time}
                </span>
              ) : row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT ? (
                <span>
                  {row.original.event.eventName} {row.original.show.date}{' '}
                  {row.original.show.time}
                </span>
              ) : (
                <span>{row.original.product.productName}</span>
              )}
            </PreloadLinkProduct>
          </div>
        </div>
      ) : row.original.productType === APP_CONST.PRODUCT_TYPE_VOUCHER_ITEM ? (
        <div className="voucher-item-wrapper">{row.original.productName}</div>
      ) : (
        <div className="product-wrapper">
          {row.original.product.productName}
        </div>
      )}
    </div>
  </div>
);

const DescriptionCell = ({ row }) => (
  <div className={styles.FlexCell}>
    <div className={styles.HeaderInline}>Beschreibung</div>
    <div className={styles.Cell}>
      <span>{row.original.product.description}</span>
    </div>
  </div>
);

const QuantityCell = ({
  row,
  data: {
    onCounterChange,
    incCounterClicked,
    decCounterClicked,
    onCheckboxChange,
  },
}) => (
  <div className={styles.FlexCell}>
    <div className={styles.HeaderInline}>Anzahl</div>
    <div className={[styles.Counter, row.original.productType].join(' ')}>
      {row.original.product &&
      row.original.product.productOrderType !==
        APP_CONST.PRODUCT_ORDER_TYPE_OPTION &&
      !row.original.product.mandatory ? (
        <Counter
          value={row.original.amount}
          incClicked={e => incCounterClicked(e, row.original.key)}
          decClicked={e => decCounterClicked(e, row.original.key)}
          onChange={e => onCounterChange(e, row.original.key)}
        />
      ) : row.original.productType === APP_CONST.PRODUCT_TYPE_VOUCHER_ITEM ? (
        <div className={styles.Cell}>{row.original.count}</div>
      ) : (
        <div className={styles.Cell} />
      )}
    </div>
  </div>
);

const RemoveCell = ({ row, data: { onDeleteItem, isRemovingFromCart } }) => (
  <div className={styles.FlexCell}>
    <div className={styles.HeaderInline}>Löschen</div>
    <div className={[styles.Cell, styles.Cross].join(' ')}>
      {isRemovingFromCart[row.value] ? (
        <span>
          <Spinner type="small" />
        </span>
      ) : (
        <div>
          {row.original.productType !== 'showProduct' ||
          !row.original.product.mandatory ? (
            <span onClick={() => onDeleteItem(row.value)}>
              <Icon icon="cross" fontSize={22} className={styles.CrossIcon} />
            </span>
          ) : (
            <span />
          )}
        </div>
      )}
    </div>
  </div>
);

export const Product = {
  Header: 'Beschreibung',
  accessor: 'product',
  headerClassName: styles.Header,
  Cell: row => <ProductCell row={row} />,
  width: 600,
  className: styles.Column,
};

export const Description = {
  Header: 'Bezeichnung',
  accessor: 'description',
  headerClassName: styles.Header,
  Cell: row => <DescriptionCell row={row} />,
  width: 400,
  className: styles.Column,
};

export const Price = {
  Header: 'Preis',
  accessor: 'price',
  Cell: row => (
    <div className={styles.FlexCell}>
      <div className={styles.HeaderInline}>Einzelpreis</div>
      <div className={styles.Cell}>
        {row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW ||
        row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT ? (
          <span />
        ) : row.original.productType !== APP_CONST.PRODUCT_TYPE_VOUCHER_ITEM ? (
          <span>&euro;{row.original.product.price}</span>
        ) : (
          <span>&euro;{row.original.price}</span>
        )}
      </div>
    </div>
  ),
  headerClassName: styles.Header,
  width: 150,
  className: styles.Column,
};

export const Quantity = (
  incCounterClicked,
  decCounterClicked,
  onCounterChange,
  onCheckboxChange
) => {
  return {
    Header: 'Anzahl',
    Cell: row => (
      <div>
        {row.original.productType === APP_CONST.PRODUCT_TYPE_SHOW ||
        row.original.productType === APP_CONST.PRODUCT_TYPE_EVENT ? (
          <div />
        ) : (
          <QuantityCell
            row={row}
            data={{
              incCounterClicked,
              decCounterClicked,
              onCounterChange,
              onCheckboxChange,
            }}
          />
        )}
      </div>
    ),
    headerClassName: styles.Header,
    width: 160,
    className: styles.Column,
  };
};

export const Total = {
  Header: 'Summe',
  accessor: 'totalPrice',
  Cell: row => (
    <div className={styles.FlexCell}>
      <div className={styles.HeaderInline}>Positionssumme</div>
      <div className={styles.Cell}>
        <span>&euro;{row.value}</span>
      </div>
    </div>
  ),
  headerClassName: styles.Header,
  width: 150,
  className: styles.Column,
};

export const Remove = (onDeleteItem, isRemovingFromCart) => {
  return {
    Header: '',
    accessor: 'key',
    Cell: row => (
      <RemoveCell row={row} data={{ onDeleteItem, isRemovingFromCart }} />
    ),
    headerClassName: styles.Header,
    width: 70,
    className: styles.Column,
  };
};
