import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './LinkItem.module.css';

const LinkItem = props => {
  const { url, exact, extern } = props;
  return (
    <div>
      {extern ? (
        <a className={styles.Link} href={url} target="_blank" rel="noreferrer">
          {props.children}
        </a>
      ) : (
        <Link
          to={url}
          exact={exact}
          className={styles.Link}
          title={props.title}
        >
          {props.children}
        </Link>
      )}
    </div>
  );
};

LinkItem.propTypes = {
  url: PropTypes.string,
  exact: PropTypes.bool,
};

export default LinkItem;
