import React from 'react';
import { connect } from 'react-redux';
import { fetchShow } from '../../../../store/actions';

import PreloadLink from '../PreloadLink';

const Show = ({ id, onFetchShow, ...props }) => {
  return <PreloadLink waiting={() => onFetchShow(id)} {...props} />;
};

const mapDispatchToProps = dispatch => ({
  onFetchShow: id => dispatch(fetchShow(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(Show);
