/* PRODUCT [START] */

export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START';
export const FETCH_PRODUCT_FAIL = 'FETCH_PRODUCT_FAIL';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';

/* PRODUCT [END] */

/* CART [START] */

export const ADD_TO_CART_START = 'ADD_TO_CART_START';
export const ADD_TO_CART_FAIL = 'ADD_TO_CART_FAIL';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';

export const REMOVE_FROM_CART_START = 'REMOVE_FROM_CART_START';
export const REMOVE_FROM_CART_FAIL = 'REMOVE_FROM_CART_FAIL';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';

export const SUBSCRIBE_CART_START = 'SUBSCRIBE_CART_START';
export const SUBSCRIBE_CART_FAIL = 'SUBSCRIBE_CART_FAIL';
export const SUBSCRIBE_CART_SUCCESS = 'SUBSCRIBE_CART_SUCCESS';

export const UNSUBSCRIBE_CART = 'UNSUBSCRIBE_CART';

/* CART [END] */

/* WISHLIST [START] */

export const ADD_TO_WISHLIST_START = 'ADD_TO_WISHLIST_START';
export const ADD_TO_WISHLIST_FAIL = 'ADD_TO_WISHLIST_FAIL';
export const ADD_TO_WISHLIST_SUCCESS = 'ADD_TO_WISHLIST_SUCCESS';

export const REMOVE_FROM_WISHLIST_START = 'REMOVE_FROM_WISHLIST_START';
export const REMOVE_FROM_WISHLIST_FAIL = 'REMOVE_FROM_WISHLIST_FAIL';
export const REMOVE_FROM_WISHLIST_SUCCESS = 'REMOVE_FROM_WISHLIST_SUCCESS';

export const SUBSCRIBE_WISHLIST_START = 'SUBSCRIBE_WISHLIST_START';
export const SUBSCRIBE_WISHLIST_FAIL = 'SUBSCRIBE_WISHLIST_FAIL';
export const SUBSCRIBE_WISHLIST_SUCCESS = 'SUBSCRIBE_WISHLIST_SUCCESS';

export const UNSUBSCRIBE_WISHLIST = 'UNSUBSCRIBE_WISHLIST';

/* WISHLIST [END] */

/* LIST [START] */

export const FETCH_LIST_START = 'FETCH_LIST_START';
export const FETCH_LIST_FAIL = 'FETCH_LIST_FAIL';
export const FETCH_LIST_SUCCESS = 'FETCH_LIST_SUCCESS';

/* LIST [END] */

/* AUTH [START] */

export const SWITCH_SHOW_AUTH = 'SWITCH_SHOW_AUTH';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SUBSCRIBE_AUTH_STATE = 'SUBSCRIBE_AUTH_STATE';
export const UNSUBSCRIBE_AUTH_STATE = 'UNSUBSCRIBE_AUTH_STATE';

/* AUTH [END] */

/* ERROR [START] */

export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

/* ERROR [END] */

/* NOTIFICATION [START] */

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

/* NOTIFICATION [END] */

/* LOADING_BAR [START] */

export const SHOW_LOADING_BAR = 'SHOW_LOADING_BAR';
export const HIDE_LOADING_BAR = 'HIDE_LOADING_BAR';

/* LOADING_BAR [END] */

// kino-at start

/* CATEGORIES [START] */

export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_FAIL = 'FETCH_CATEGORIES_FAIL';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';

/* LIST [END] */

/* CATEGORY [START] */

export const FETCH_CATEGORY_START = 'FETCH_CATEGORY_START';
export const FETCH_CATEGORY_FAIL = 'FETCH_CATEGORY_FAIL';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';

/* CATEGORY [END] */

/* PRODUCT_IMAGE [START] */

export const FETCH_PRODUCT_IMAGE_START = 'FETCH_PRODUCT_IMAGE_START';
export const FETCH_PRODUCT_IMAGE_FAIL = 'FETCH_PRODUCT_IMAGE_FAIL';
export const FETCH_PRODUCT_IMAGE_SUCCESS = 'FETCH_PRODUCT_IMAGE_SUCCESS';

/* PRODUCT_IMAGE [END] */

// kino-at end

/* SHOWS [START] */

export const FETCH_SHOWS_START = 'FETCH_SHOWS_START';
export const FETCH_SHOWS_FAIL = 'FETCH_SHOWS_FAIL';
export const FETCH_SHOWS_SUCCESS = 'FETCH_SHOWS_SUCCESS';
export const FETCH_PUBLIC_EVENTS_SUCCESS = 'FETCH_PUBLIC_EVENTS_SUCCESS';

/* SHOWS [END] */

export const FETCH_UPCOMING_SHOWS_START = 'FETCH_UPCOMING_SHOWS_START';
export const FETCH_UPCOMING_SHOWS_FAIL = 'FETCH_UPCOMING_SHOWS_FAIL';
export const FETCH_UPCOMING_SHOWS_SUCCESS = 'FETCH_UPCOMING_SHOWS_SUCCESS';

export const SAVE_MOVIE_REMINDER_SUBSCRIPTION_START =
  'SAVE_MOVIE_REMINDER_SUBSCRIPTION_START';
export const SAVE_MOVIE_REMINDER_SUBSCRIPTION_FAIL =
  'SAVE_MOVIE_REMINDER_SUBSCRIPTION_FAIL';
export const SAVE_MOVIE_REMINDER_SUBSCRIPTION_SUCCESS =
  'SAVE_MOVIE_REMINDER_SUBSCRIPTION_SUCCESS';

/* SHOWS [START] */

export const FETCH_SHOW_START = 'FETCH_SHOW_START';
export const FETCH_SHOW_FAIL = 'FETCH_SHOW_FAIL';
export const FETCH_SHOW_SUCCESS = 'FETCH_SHOW_SUCCESS';

/* SHOWS [END] */

/* COOKIE LAW [START] */

export const COOKIELAW_CONSENT_GIVEN = 'COOKIELAW_CONSENT_GIVEN';
export const COOKIELAW_ACCEPT_PREF = 'COOKIELAW_ACCEPT_PREF';
export const COOKIELAW_ACCEPT_STAT = 'COOKIELAW_ACCEPT_STAT';
export const COOKIELAW_ACCEPT_MARK = 'COOKIELAW_ACCEPT_MARK';
export const COOKIELAW_DECLINE_PREF = 'COOKIELAW_DECLINE_PREF';
export const COOKIELAW_DECLINE_STAT = 'COOKIELAW_DECLINE_STAT';
export const COOKIELAW_DECLINE_MARK = 'COOKIELAW_DECLINE_MARK';

/* COOKIE LAW [END] */

/* SAVE ORDER [START] */
export const SAVE_ORDER_START = 'SAVE_ORDER_START';
export const SAVE_ORDER_FAIL = 'SAVE_ORDER_FAIL';
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
/* SAVE ORDER [END] */

/* UPDATE CART [START] */
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAIL = 'UPDATE_CART_FAIL';
export const UPDATE_CART_START = 'UPDATE_CART_START';
/* UPDATE CART [END] */

/* FETCH CHECKOUT STATUS [START] */
export const FETCH_CHECKOUT_SUCCESS = 'FETCH_CHECKOUT_SUCCESS';
export const FETCH_CHECKOUT_FAIL = 'FETCH_CHECKOUT_FAIL';
export const FETCH_CHECKOUT_START = 'FETCH_CHECKOUT_START';
/* FETCH CHECKOUT STATUS [END] */

/* FETCH CHECKOUT STATUS [START] */
export const FETCH_GIFTCARD_SUCCESS = 'FETCH_GIFTCARD_SUCCESS';
export const FETCH_GIFTCARD_FAIL = 'FETCH_GIFTCARD_FAIL';
export const FETCH_GIFTCARD_START = 'FETCH_GIFTCARD_START';
/* FETCH CHECKOUT STATUS [END] */

/* FETCH CHECKOUT STATUS [START] */
export const APPLY_DISCOUNT_CODE_START = 'APPLY_DISCOUNT_CODE_START';
export const APPLY_DISCOUNT_CODE_FAIL = 'APPLY_DISCOUNT_CODE_FAIL';
export const APPLY_DISCOUNT_CODE_SUCCESS = 'APPLY_DISCOUNT_CODE_SUCCESS';
/* FETCH CHECKOUT STATUS [END] */

export const PROCESS_PURCHASE_START = 'PROCESS_PURCHASE_START';
export const PROCESS_PURCHASE_FAIL = 'PROCESS_PURCHASE_FAIL';
export const PROCESS_PURCHASE_SUCCESS = 'PROCESS_PURCHASE_SUCCESS';
export const SHOW_PRODUCT_SUCCESS = 'SHOW_PRODUCT_SUCCESS';

/* SAVE ORDER [START] */
export const SAVE_CUSTOMER_FEEDBACK_FORM_START =
  'SAVE_CUSTOMER_FEEDBACK_FORM_START';
export const SAVE_CUSTOMER_FEEDBACK_FORM_FAIL =
  'SAVE_CUSTOMER_FEEDBACK_FORM_FAIL';
export const SAVE_CUSTOMER_FEEDBACK_FORM_SUCCESS =
  'SAVE_CUSTOMER_FEEDBACK_FORM_SUCCESS';
/* SAVE ORDER [END] */

/* FETCH BLOGS [START] */
export const FETCH_BLOGS_START = 'FETCH_BLOGS_START';
export const FETCH_BLOGS_FAIL = 'FETCH_BLOGS_FAIL';
export const FETCH_BLOGS_SUCCESS = 'FETCH_BLOGS_SUCCESS';
/* FETCH BLOGS [START] */

/* MOVIE_DETAIL [START] */

export const FETCH_MOVIE_DETAIL_START = 'FETCH_MOVIE_DETAIL_START';
export const FETCH_MOVIE_DETAIL_FAIL = 'FETCH_MOVIE_DETAIL_FAIL';
export const FETCH_MOVIE_DETAIL_SUCCESS = 'FETCH_MOVIE_DETAIL_SUCCESS';

/* MOVIE_DETAIL [END] */

/* SAVE JOB APPLICATION FORM [START] */
export const SAVE_JOB_APPLIACTION_FORM_START =
  'SAVE_JOB_APPLIACTION_FORM_START';
export const SAVE_JOB_APPLIACTION_FORM_FAIL = 'SAVE_JOB_APPLIACTION_FORM_FAIL';
export const SAVE_JOB_APPLIACTION_FORM_SUCCESS =
  'SAVE_JOB_APPLIACTION_FORM_SUCCESS';
/* SAVE JOB APPLICATION FORM [END] */

export const SHOW_APPLY_DISCOUNT_CODE_START = 'SHOW_APPLY_DISCOUNT_CODE_START';
export const SHOW_APPLY_DISCOUNT_CODE_FAIL = 'SHOW_APPLY_DISCOUNT_CODE_FAIL';
export const SHOW_APPLY_DISCOUNT_CODE_SUCCESS =
  'SHOW_APPLY_DISCOUNT_CODE_SUCCESS';

export const FETCH_ORDER_STORNO_START = 'FETCH_ORDER_STORNO_START';
export const FETCH_ORDER_STORNO_FAIL = 'FETCH_ORDER_STORNO_FAIL';
export const FETCH_ORDER_STORNO_SUCCESS = 'FETCH_ORDER_STORNO_SUCCESS';

export const PROCESS_ORDER_STORNO_START = 'PROCESS_ORDER_STORNO_START';
export const PROCESS_ORDER_STORNO_FAIL = 'PROCESS_ORDER_STORNO_FAIL';
export const PROCESS_ORDER_STORNO_SUCCESS = 'PROCESS_ORDER_STORNO_SUCCESS';
