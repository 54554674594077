import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './ActionsBox.module.css';
import Kinostyles from '../../Util/AutokinoStylesComponent.module.css';
import { EVENT_ITEM } from '../../../constants/routes';

const ActionsBox = props => {
  const {
    additionalClassName,
    addedToCart,
    addingToCart,
    inCart,
    inWishlist,
    toggledWishlist,
    togglingWishlist,
    showId,
    linkTarget,
    linkTitle,
  } = props;

  const showMore = (
    <button
      onClick={null}
      className={Kinostyles.CTAButton}
      disabled={addingToCart}
    >
      <Link
        to={linkTarget}
        title="Mehr Info..."
        className={Kinostyles.CTAButtonLink}
      >
        {props.linkTitle}
      </Link>
    </button>
  );

  const buyTicket = (
    <button
      onClick={null}
      className={styles.AddToCart + ' ' + Kinostyles.CTAButton}
      disabled={addingToCart}
    >
      <Link to={linkTarget} title="Ticket kaufen" className="btn btn-first">
        {' '}
        Ticket kaufen
      </Link>
    </button>
  );

  return <div className={styles.Buttons}>{showMore}</div>;
  /*{buyTicket}*/
};

ActionsBox.propTypes = {
  additionalClassName: PropTypes.string,
  addedToCart: PropTypes.func,
  addingToCart: PropTypes.bool,
  inCart: PropTypes.bool,
  inWishlist: PropTypes.bool,
  toggledWishlist: PropTypes.func,
  togglingWishlist: PropTypes.bool,
  showId: PropTypes.number,
  linkTitle: PropTypes.string,
};

export default ActionsBox;
