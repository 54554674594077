import * as actionTypes from './actionTypes';
import { addError } from './errors';
import { showLoadingBar, hideLoadingBar } from './loadingBar';

export const fetchProductStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_START,
  };
};

export const fetchProductFail = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_FAIL,
  };
};

export const fetchProductSuccess = product => {
  return {
    type: actionTypes.FETCH_PRODUCT_SUCCESS,
    product,
  };
};

export const fetchProduct = id => async (dispatch, getState) => {
  if (getState().product.product && getState().product.product.id === id) {
    return;
  }
  dispatch(fetchProductStart());
  dispatch(showLoadingBar(id));
  try {
    let product = {
      title: 'Title',
      price: 10,
      oldPrice: 5,
      summary: 'Cool',
      reviews: {},
      categories: {},
      tags: {},
      rating: 60,
      images: [],
      onSale: true,
    };

    dispatch(fetchProductSuccess(product));
    // dispatch(fetchProductSuccess(doc.data()));
    dispatch(hideLoadingBar(id));
  } catch (error) {
    dispatch(fetchProductFail());
    dispatch(addError());
    dispatch(hideLoadingBar(id));
  }
};
