import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import 'react-toastify/dist/ReactToastify.min.css';

import Notification from '../UI/Notification/Notification';
import Layout from '../../containers/Layout/Layout';
import Auth from '../../containers/Auth/Auth';
import * as actions from '../../store/actions/';
import Routes from '../Routes/Routes';
import LoadingBar from '../UI/LoadingBar/LoadingBar';
import { CookieBanner } from '@palmabit/react-cookie-law';
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent';
import cookie from 'react-cookies';
import CartPage from '../../pages/CartPage/CartPage';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import ScriptTag from 'react-script-tag/lib/ScriptTag';
import moment from 'moment/moment';

class App extends Component {
  state = {
    isSubscribed: false,
    cart: this.props.cart,
  };

  componentDidMount() {
    document.getElementById('initial_loader').classList.add('hidden');
    this.props.onSubscribeAuthState();
    //var item = cookie.load('cart', false);
    var item = localStorage.getItem('cart');
    if (item) {
      try {
        item = JSON.parse(item);
      } catch {
        localStorage.setItem('cart', '{}');
      }
    }

    //console.log("######## cookie consent: "+getCookieConsentValue("autokinoTicketshopCookie") + " value: "+ (getCookieConsentValue("autokinoTicketshopCookie")==="true"));
    if (
      getCookieConsentValue('autokinoTicketshopCookie') &&
      getCookieConsentValue('autokinoTicketshopCookie') === 'true'
    ) {
      this.initTracking();
    }

    console.log('componentDidMount cart item loaded: ' + JSON.stringify(item));
    if (item && item !== undefined) {
      //check for expired items and clear basket if ticket for expired show is present

      var clearbasket = false;
      Object.values(item).forEach(p => {
        if (p.show && p.show.date) {
          let showdate = moment(p.show.date, 'DD.MM.YYYY');
          let currentDate = moment();
          if (showdate.isValid() && showdate.isBefore(currentDate)) {
            console.log(
              'showdate: ' + p.show.date + ' is smaller than current date'
            );
            clearbasket = true;
          }
        }
        console.log(JSON.stringify(p));
      });
      console.log('clearBasket: ' + clearbasket);
      if (clearbasket) {
        localStorage.setItem('cart', '{}');
      } else {
        let total = CartPage.calcTotalPrice(item);
        // let cart = JSON.parse(item);
        this.props.onSubscribeCart(item, total);
      }
      // this.setState({ cart : item });
    }
  }

  initTracking() {
    console.log('!!!init tracking called');
    ReactGA.initialize('G-2ZNV6BW413');
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: true, // enable logs
    };
    const ReactPixel = require('react-facebook-pixel');
    ReactPixel.default.init('2123680111249163', null, options);

    ReactPixel.default.revokeConsent();
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log('getDerivedStateFromProps');
  //   // if (nextProps.isAuthenticated && !prevState.isSubscribed) {
  //   //   nextProps.onSubscribeWishlist();
  //   //   nextProps.onSubscribeCart();

  //   //   // Prefetch Cart and Wishlist pages after authentification
  //   //   // import(/* webpackChunkName: "cart" */ '../../pages/CartPage/CartPage');
  //   //   // import(/* webpackChunkName: "wishlist" */ '../../pages/WishlistPage/WishlistPage');

  //   //   return { isSubscribed: true };
  //   // } else if (!nextProps.isAuthenticated && prevState.isSubscribed) {
  //   //   nextProps.onUnsubscribeWishlist();
  //   //   nextProps.onUnsubscribeCart();
  //   //   return { isSubscribed: false };
  //   // }
  //   return null;
  // }

  componentWillUnmount() {
    actions.unsubscribeAuthState();
    if (this.state.isSubscribed) {
      this.props.onUnsubscribeCart();
    }
  }

  render() {
    return (
      <div>
        <Helmet
          defaultTitle="Autokino Wien / Flohmarkt Wien"
          titleTemplate="%s"
          defer={false}
        />

        <Auth />

        <Layout>
          <Routes />
        </Layout>

        <ToastContainer />

        <LoadingBar show={this.props.showLoadingBar} />

        <Notification
          text={this.props.errorText}
          show={this.props.error}
          onOpen={this.props.onRemoveError}
          options={{ type: 'fail' }}
        />

        <Notification
          text={this.props.notificationText}
          show={this.props.notification}
          onOpen={this.props.onRemoveNotification}
          options={{ type: 'success' }}
        />

        {/*} <CookieBanner
          message="Diese Website benutzt Cookies. Wenn Sie die Website weiter nutzen, gehen wir von Ihrem Einverständnis aus."
          privacyPolicyLinkText="Datenschutzerklärung"
          acceptButtonText="Akzeptieren"
          declineButtonText="Abbrechen"
          savePreferencesButtonText="Speichern und Schließen"
          managePreferencesButtonText="Einstellungen bearbeiten"
          wholeDomain={true}
          showPreferencesOption={false}
          preferencesOptionText="Einstellungen"
          necessaryOptionText="Aufruf der Webseite"
          statisticsOptionText="Statistikdaten"
          marketingOptionText="Marketingdaten"
          policyLink={'./privacy'}
          onAccept={() => {
            ReactPixel.grantConsent();
          }}
          onAcceptPreferences={this.props.onAcceptPreferences}
          onAcceptStatistics={this.props.onAcceptStatistics}
          onAcceptMarketing={this.props.onAcceptMarketing}
          onDeclinePreferences={this.props.onDeclinePreferences}
          onDeclineStatistics={this.props.onDeclineStatistics}
          onDeclineMarketing={this.props.onDeclineMarketing}
          styles={{
            button: { class: 'btn btn-first' },
            message: { class: 'react-cookie-law-msg' },
          }}
        />  */}
        <CookieConsent
          location="top"
          buttonText="Akzeptieren"
          declineButtonText="Ablehnen"
          enableDeclineButton={true}
          cookieName="autokinoTicketshopCookie"
          style={{ background: '#2B373B' }}
          expires={1000}
          onAccept={acceptedByScrolling => {
            this.initTracking();
          }}
          onDecline={() => {
            console.log('cookie consent declined, do not init tracking');
          }}
        >
          Diese Website benutzt Cookies. Wenn Sie die Website weiter nutzen,
          gehen wir von Ihrem Einverständnis aus.{' '}
        </CookieConsent>
        <ScriptTag
          type="text/javascript"
          src="https://app.mailjet.com/pas-nc-embedded-v1.js"
          isHydrating={true}
        />
      </div>
    );
  }
}

App.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  notification: PropTypes.bool,
  notificationText: PropTypes.string,
  cart: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    error: state.errors.error,
    errorText: state.errors.text,
    notification: state.notifications.notification,
    notificationText: state.notifications.text,
    isAuthenticated: state.auth.isAuth,
    cart: state.cart.cart,
    showLoadingBar: state.loadingBar.show,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubscribeWishlist: () => dispatch(actions.subscribeWishlist()),
    onUnsubscribeWishlist: () => dispatch(actions.unsubscribeWishlist()),
    onSubscribeCart: (cart, total) => dispatch(actions.updateCart(cart, total)),
    onUnsubscribeCart: () => dispatch(actions.unsubscribeCart()),
    onRemoveError: () => dispatch(actions.removeError()),
    onSubscribeAuthState: () => dispatch(actions.subscribeAuthState()),
    onRemoveNotification: () => dispatch(actions.removeNotification()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
