import React from 'react';
import PropTypes from 'prop-types';

import { animateScroll as scroll } from 'react-scroll';
import Kinostyles from '../Util/AutokinoStylesComponent.module.css';
import { useRef } from 'react';

function openNewsletterPopup(event) {
  window.mjOpenPopin(event, event.target);
  scroll.scrollTo(0);
}
const NewsletterSignup = props => {
  const ref = useRef(null);
  var visible = false;
  return (
    <div className={'mt-2'}>
      <button
        className={Kinostyles.CTAButton}
        data-token="79a8b7d7490ce8b0e98cf53e9a1f18e0"
        id="filmprogrammNewsletterButton"
        onClick={event => {
          console.log('showSignup clicked ');
          if (!visible) {
            ref.current.style.display = 'block';
            visible = true;
          } else {
            ref.current.style.display = 'none';
            visible = false;
          }
        }}
      >
        {props.title ? props.title : 'Newsletter abonnieren'}
      </button>
      <iframe
        ref={ref}
        data-w-type="embedded"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://x958m.mjt.lu/wgt/x958m/vhm/form?c=7aafc8ce"
        width="100%"
        style={{ display: 'none', height: 0 }}
      />
    </div>
  );
};

NewsletterSignup.propTypes = {
  title: PropTypes.string,
};

export default NewsletterSignup;
