import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils';

const initialState = {
  orderId: 0,
  orderLink: '',
  posted: false,
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_ORDER_START:
      return updateObject(state, { loading: true });

    case actionTypes.SAVE_ORDER_FAIL:
      return updateObject(state, { error: true, loading: false, posted: true });

    case actionTypes.SAVE_ORDER_SUCCESS:
      return updateObject(state, {
        orderId: action.orderId,
        orderLink: action.orderLink,
        loading: false,
        error: false,
        posted: false,
      });
    case actionTypes.SAVE_CUSTOMER_FEEDBACK_FORM_START:
      return updateObject(state, { loading: true });

    case actionTypes.SAVE_CUSTOMER_FEEDBACK_FORM_FAIL:
      return updateObject(state, { error: true, loading: false, posted: true });

    case actionTypes.SAVE_CUSTOMER_FEEDBACK_FORM_SUCCESS:
      return updateObject(state, {
        loading: false,
        error: false,
        posted: false,
      });
    case actionTypes.SAVE_JOB_APPLIACTION_FORM_SUCCESS:
      return updateObject(state, {
        loading: false,
        error: false,
        resetInputs: true,
      });
    default:
      return state;
  }
};

export default reducer;
