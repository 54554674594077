import * as actionTypes from './actionTypes';
import { addError } from './errors';

import API from '../../services/WsApi';

export const fetchListStart = listID => {
  return {
    type: actionTypes.FETCH_LIST_START,
    listID,
  };
};

export const fetchListFail = (error, listID) => {
  return {
    type: actionTypes.FETCH_LIST_FAIL,
    listID,
  };
};

export const fetchListSuccess = (products, listID) => {
  console.log('!!!fetchListSuccess, listID = ' + listID);

  return {
    type: actionTypes.FETCH_LIST_SUCCESS,
    products,
    listID,
  };
};

export const fetchList = id => async (dispatch, getState) => {
  dispatch(fetchListStart(id));
  console.log('fetchListStart id = ' + id);
  try {
    API.get('movie/getCurrentMovies')
      .then(function(response) {
        console.log('then = ' + response.data[0].movieName);
        dispatch(fetchListSuccess(response.data, id));
      })
      .catch(function(error) {
        dispatch(fetchListFail(id));
        dispatch(addError());
      })
      .finally(function() {
        console.log('fetchListEnd');
      });
  } catch (error) {
    dispatch(fetchListFail(id));
    dispatch(addError());
  }
};

// kino-at start
export const fetchCategoriesStart = () => {
  return {
    type: actionTypes.FETCH_CATEGORIES_START,
  };
};

export const fetchCategoriesFail = error => {
  return {
    type: actionTypes.FETCH_CATEGORIES_FAIL,
  };
};

export const fetchCategoriesSuccess = categories => {
  console.log('fetch categories success = ' + categories.length);
  return {
    type: actionTypes.FETCH_CATEGORIES_SUCCESS,
    categories,
  };
};

export const fetchCategories = () => async (dispatch, getState) => {
  dispatch(fetchCategoriesStart());
  console.log('fetch Categories');
  try {
    // const IDsa =0;
    console.log('fetch Categories');
    API.get('product/getProductCategories')
      .then(function(response) {
        dispatch(fetchCategoriesSuccess(response.data));
      })
      .catch(function(error) {
        dispatch(fetchCategoriesFail());
      });
  } catch (error) {
    dispatch(fetchCategoriesFail());
    dispatch(addError());
  }
};
//kino-at end
