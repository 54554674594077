import React from 'react';
import PropTypes from 'prop-types';

import styles from './Counter.module.css';
import Icon from '../Icon/Icon';

const Counter = props => {
  const {
    decClicked,
    incClicked,
    onChange,
    onBlur,
    value,
    className,
    disabled,
    fixed,
  } = props;
  return (
    <div className={[styles.Container, className].join(' ')}>
      {!disabled ? (
        <span onClick={decClicked}>
          <Icon
            icon="minus-round"
            className={[styles.ControlIcon, styles.left].join(' ')}
            type="ionic"
          />
        </span>
      ) : (
        <span />
      )}
      <input
        className={styles.Input}
        type="number"
        disabled={disabled ? 'disabled' : ''}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        step="1"
        name="counter"
      />
      {!disabled ? (
        <span onClick={incClicked}>
          <Icon
            icon="plus-round"
            className={[styles.ControlIcon, styles.right].join(' ')}
            type="ionic"
          />
        </span>
      ) : (
        <span />
      )}
    </div>
  );
};

Counter.propTypes = {
  decClicked: PropTypes.func,
  incClicked: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Counter.defaultProps = {
  value: 1,
};

export default Counter;
